import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as orderListSlice } from 'store/slices/orderListSlice';
import {
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { FormLabel, Grid } from '@mui/material';
import l from 'helpers/l';
import { EditTable, Filters, PageLayout } from 'components';
import { Check as CheckIcon } from '@mui/icons-material';
import { RootState } from 'store';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';
import { formatFilters } from 'helpers/filters';
import { isEmpty } from 'lodash';
import { actions as contactPersonActions } from 'store/slices/contactPersonsSlice';
import { actions as clientActions } from 'store/slices/clientsSlice';
import { actions as employeeActions } from 'store/slices/employeeListSlice';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { actions as authActions } from 'store/slices/auth';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  mainWrapper: {
    '& .MuiBadge-dot': {
      display: 'none',
    },
  },
}));

interface OrderListFilters {
  orderDateFrom: string;
  orderDateTo: string;
}
const initialValues: OrderListFilters = {
  orderDateFrom: '',
  orderDateTo: '',
};

function OrderListPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orderDateFromFormat, setOrderDateFromFormat] = useState<any>(null);
  const [orderDateToFormat, setOrderDateToFormat] = useState<any>(null);

  useSelector((state: RootState) => state.common.loading);
  const { items, meta } = useSelector((state: RootState) => state.orderList);

  const [dropdownValues, setDropdownValues] = useState({});
  const [sort, setSort] = useState({});

  const [resetFilter, setResetFilter] = useState<boolean>(false);

  const addEntryHandler = useCallback(() => {
    navigate('/add-order-list');
  }, [navigate]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      navigate(`/edit-order-list/${params?.row?.uuid}`);
    },
    [navigate],
  );

  const viewEntryHandler = useCallback(
    (params: GridRowParams) => {
      // navigate(`/order-list/${params?.row?.uuid}`);
    },
    [navigate],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        limit: 50,
        ...dropdownValues,
      };

      dispatch(orderListSlice.sendEntriesFilters(params));
    },
    [dispatch, dropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1, limit: 50 };

      dispatch(orderListSlice.sendEntriesFilters(params));
    },
    [dispatch, dropdownValues, sort],
  );

  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values: OrderListFilters) => {
      const formatValues = formatFilters(values);
      const objectForDropdownValues: any = {};

      let orderDateFrom = '';
      let orderDateTo = '';
      if (orderDateFromFormat !== null) {
        orderDateFrom = moment(
          new Date(dateFormatter(orderDateFromFormat)),
        ).format('YYYY-MM-DDTHH:mm:ss.sssZ');
        objectForDropdownValues.orderDateFrom = orderDateFrom;
      }
      if (orderDateToFormat !== null) {
        orderDateTo = moment(new Date(dateFormatter(orderDateToFormat))).format(
          'YYYY-MM-DDTHH:mm:ss.sssZ',
        );
        objectForDropdownValues.orderDateTo = orderDateTo;
      }
      setDropdownValues({
        ...formatValues,
        ...objectForDropdownValues,
      });
      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort, orderDateFrom, orderDateTo };

        dispatch(orderListSlice.sendEntriesFilters(params));
      } else {
        dispatch(orderListSlice.sendEntriesFilters(sort));
      }
    },
  });

  const dateFormatter = (string: any) => {
    if (string !== null) {
      const splitDate = string.split('.');
      return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    } else return '';
  };

  const onReset = useCallback(() => {
    setResetFilter(true);
    setValues(initialValues);
    setDropdownValues({});
    setOrderDateFromFormat(null);
    setOrderDateToFormat(null);
    dispatch(orderListSlice.sendEntriesFilters(sort));
  }, [dispatch, setValues, sort]);

  useEffect(() => {
    const params = {
      limit: 50,
    };
    dispatch(orderListSlice.getOrderList(params));
    dispatch(contactPersonActions.getContactPersonsList());
    dispatch(clientActions.getClients());
    dispatch(employeeActions.getEmployeesList());
    dispatch(authActions.authMe());
  }, [dispatch]);

  useEffect(() => {
    setResetFilter(false);
  }, [resetFilter]);

  const handleOrderDateChange = (value: any, inputValue: any) => {
    setOrderDateFromFormat(inputValue);
  };
  const handleOrderDateToChange = (value: any, inputValue: any) => {
    setOrderDateToFormat(inputValue);
  };

  const datePickerStyles = {
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '12px',
  };

  return (
    <PageLayout
      title={'Client Order Entries'}
      fullWidth={true}
      className={classes.mainWrapper}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid width={'100%'} container spacing={1} alignItems="start">
          <Grid item md={4}>
            <FormLabel>From Due Date</FormLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                autoOk={true}
                showTodayButton={true}
                value={orderDateFromFormat}
                inputValue={orderDateFromFormat}
                onChange={handleOrderDateChange}
                format="DD.MM.YYYY"
                style={datePickerStyles}
                error={Boolean(formik.errors.orderDateFrom)}
                helperText={formik.errors.orderDateFrom}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={4}>
            <FormLabel>To Due Date</FormLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                autoOk={true}
                showTodayButton={true}
                value={orderDateToFormat}
                inputValue={orderDateToFormat}
                onChange={handleOrderDateToChange}
                format="DD.MM.YYYY"
                style={datePickerStyles}
                error={Boolean(formik.errors.orderDateTo)}
                helperText={formik.errors.orderDateTo}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        rows={items}
        columns={[
          {
            field: 'id',
            headerName: 'Id',
            width: 80,
          },
          {
            field: 'clientName',
            headerName: 'Client Name',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.contactPerson?.client?.name,
          },
          {
            field: 'contactPerson',
            headerName: 'Contact Person',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.contactPerson.nick,
          },
          {
            field: 'accountManager',
            headerName: 'Account Manager',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.contactPerson.nick,
          },
          {
            field: 'orderedAt',
            headerName: 'Order Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.orderedAt).format('DD.MM.YYYY'),
          },

          {
            field: 'dueDatetime',
            headerName: 'Due Date/time',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.dueDatetime).format('DD.MM.YYYY'),
          },

          {
            field: 'isCorrection',
            headerName: 'Correction',
            width: 130,
            renderCell: (params: any) => {
              return params?.value ? <CheckIcon color="secondary" /> : <></>;
            },
          },
          {
            field: 'isRatePerArticleClient',
            headerName: 'Rate Per Article Client',
            width: 130,
            renderCell: (params: any) => {
              return params?.value ? <CheckIcon color="secondary" /> : <></>;
            },
          },
        ]}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        // onDelete={deleteEntryHandler}
        onView={viewEntryHandler}
        editColumnWidth={140}
        sortedFieldIndex={5}
        onSortModelChange={handleSort}
        sortingOrderDirection="desc"
        pagination
        page={meta?.currentPage - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={50}
        rowsPerPageOptions={[50]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
}

export default memo(OrderListPage);

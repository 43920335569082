import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getOrderListApi = (params?: any) =>
  apiCall({
    url: Endpoint.OrderList,
    method: Method.Get,
    params,
  });

const addOrderListApi = (data: any) =>
  apiCall({
    url: Endpoint.OrderList,
    method: Method.Post,
    data,
  });

const getOrderListItemApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderList,
    method: Method.Get,
    urlSuffix,
  });

const editOrderListApi = (urlSuffix: string, data: any) => {
  return apiCall({
    url: Endpoint.OrderList,
    method: Method.Put,
    urlSuffix,
    data,
  });
};

const deleteOrderListApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderList,
    method: Method.Delete,
    urlSuffix,
  });
const cancelAllOrderLinesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.CancelAllOrderLines,
    method: Method.Put,
    urlSuffix,
  });

const sendOrderListFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.OrderList,
    method: Method.Get,
    params,
  });

export {
  getOrderListApi,
  addOrderListApi,
  getOrderListItemApi,
  editOrderListApi,
  deleteOrderListApi,
  sendOrderListFiltersApi,
  cancelAllOrderLinesApi,
};

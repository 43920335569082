import { EmployeeDetail } from 'components';
import { empKeys, empValues } from 'enums/employeeEnums';
import { formatTime } from 'helpers/timeHelpers';
import { Endpoint } from '../../enums/APIEndpointEnum';

export const renderItem = ([key, value]: any) => {
  switch (key) {
    case empKeys.firstName: {
      return <EmployeeDetail title={empValues.firstName} text={value} />;
    }
    case empKeys.lastName: {
      return <EmployeeDetail title={empValues.lastName} text={value} />;
    }
    case empKeys.id: {
      return <EmployeeDetail title={empValues.id} text={value} />;
    }
    case empKeys.email: {
      return (
        <EmployeeDetail title={empValues.email} email={true} text={value} />
      );
    }
    case empKeys.status: {
      return <EmployeeDetail title={empValues.status} text={value?.name} />;
    }
    case empKeys.createdAt: {
      return (
        <EmployeeDetail title={empValues.createdAt} text={formatTime(value)} />
      );
    }
    case empKeys.updatedAt: {
      return (
        <EmployeeDetail title={empValues.updatedAt} text={formatTime(value)} />
      );
    }
    case empKeys.language: {
      return <EmployeeDetail title={empValues.language} text={value?.name} />;
    }
    case empKeys.timezone: {
      return <EmployeeDetail title={empValues.timezone} text={value?.name} />;
    }
    case empKeys.isExpert: {
      return (
        <EmployeeDetail title={empValues.isExpert} text={value} type="check" />
      );
    }
    case empKeys.countryOfOrigin: {
      return (
        <EmployeeDetail title={empValues.countryOfOrigin} text={value?.name} />
      );
    }
    case empKeys.countryOfResidence: {
      return (
        <EmployeeDetail
          title={empValues.countryOfResidence}
          text={value?.name}
        />
      );
    }
    case empKeys.currency: {
      return <EmployeeDetail title={empValues.currency} text={value?.name} />;
    }
    case empKeys.availableHours: {
      return <EmployeeDetail title={empValues.availableHours} text={value} />;
    }
    case empKeys.hourlyRate: {
      return <EmployeeDetail title={empValues.hourlyRate} text={value} />;
    }
    case empKeys.startDate: {
      return (
        <EmployeeDetail
          title={empValues.startDate}
          text={value && formatTime(value)}
        />
      );
    }
    case empKeys.isFirstInterview: {
      return (
        <EmployeeDetail
          title={empValues.isFirstInterview}
          text={value}
          type="check"
        />
      );
    }
    case empKeys.isNewEmployee: {
      return (
        <EmployeeDetail
          title={empValues.isNewEmployee}
          text={value}
          type="check"
        />
      );
    }
    case empKeys.isSeniorEmployee: {
      return (
        <EmployeeDetail
          title={empValues.isSeniorEmployee}
          text={value}
          type="check"
        />
      );
    }
    case empKeys.linkedinUrl: {
      return (
        <EmployeeDetail
          title={empValues.linkedinUrl}
          text={value}
          link={value}
          type="externalUrl"
        />
      );
    }
    case empKeys.cvUrl: {
      return (
        <EmployeeDetail
          title={empValues.cvUrl}
          text={value}
          link={value}
          type="externalUrl"
        />
      );
    }
    case empKeys.qa: {
      return <EmployeeDetail title={empValues.qa} text={value} />;
    }
    case empKeys.paymentMethod: {
      return (
        <EmployeeDetail title={empValues.paymentMethod} text={value?.name} />
      );
    }
    case empKeys.paymentDetails: {
      return <EmployeeDetail title={empValues.paymentDetails} text={value} />;
    }
    case empKeys.passportNumber: {
      return <EmployeeDetail title={empValues.passportNumber} text={value} />;
    }
    case empKeys.isSignedContract: {
      return (
        <EmployeeDetail
          title={empValues.isSignedContract}
          link={value}
          text={value}
          type="check"
        />
      );
    }
    case empKeys.referredBy: {
      return (
        <EmployeeDetail
          title={empValues.referredBy}
          text={`${value?.firstName || ''} ${value?.lastName || ''}`}
          link={`${Endpoint.EmployeesList}/${value?.uuid || ''}`}
          type="internalUrl"
        />
      );
    }
    case empKeys.teamLead: {
      return (
        <EmployeeDetail
          title={empValues.teamLead}
          text={`${value?.firstName || ''} ${value?.lastName || ''}`}
        />
      );
    }
    case empKeys.isTranslationOnly: {
      return (
        <EmployeeDetail
          title={empValues.isTranslationOnly}
          link={value}
          text={value}
          type="check"
        />
      );
    }
    case empKeys.contactPerson: {
      return (
        <EmployeeDetail
          title={empValues.contactPerson}
          text={`${value?.nick || ''}`}
        />
      );
    }
    default: {
      return '';
    }
  }
};

export const switchPlaces = (data: any) => {
  const {
    firstName,
    lastName,
    id,
    email,
    status,
    createdAt,
    updatedAt,
    language,
    timezone,
    isExpert,
    strike,
    countryOfOrigin,
    countryOfResidence,
    currency,
    availableHours,
    hourlyRate,
    startDate,
    isFirstInterview,
    isNewEmployee,
    isSeniorEmployee,
    linkedinUrl,
    cvUrl,
    qa,
    paymentDetails,
    paymentMethod,
    passportNumber,
    isSignedContract,
    referredBy,
    teamLead,
    isTranslationOnly,
    contactPerson,
  } = data;

  return {
    id,
    firstName,
    lastName,
    email,
    status,
    createdAt,
    updatedAt,
    language,
    timezone,
    isExpert,
    strike,
    countryOfOrigin,
    countryOfResidence,
    currency,
    availableHours,
    hourlyRate,
    startDate,
    isFirstInterview,
    isNewEmployee,
    isSeniorEmployee,
    linkedinUrl,
    cvUrl,
    qa,
    paymentDetails,
    paymentMethod,
    passportNumber,
    isSignedContract,
    referredBy,
    teamLead,
    isTranslationOnly,
    contactPerson,
  };
};

export const inactive = ['new', 'active', 'locked'];

import { memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Switch } from '@mui/material';
import { Form, TextField } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { actions as expertiseActions } from 'store/slices/experiseSlice';
import { actions as speakersActions } from 'store/slices/speakersSlice';
import { actions as voicesActions } from 'store/slices/voicesSlice';
import { actions as targetAudienceActions } from 'store/slices/targetAudienceSlice';
import { actions as orderLineStatusesActions } from 'store/slices/orderLineStatusesSlice';
import { createMenuItems } from 'helpers/dropdown';
import { useParams } from 'react-router-dom';
import colors from 'styles/colors';
import { makeStyles } from '@mui/styles';
import { Endpoint } from 'enums/APIEndpointEnum';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import FormInfoRow from 'components/FormInfoRow/FormInfoRow';

const useStyles = makeStyles(() => ({
  formRoot: {
    '& .boxRow': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      '& label.MuiFormLabel-root': {
        width: 300,
      },
      '& .MuiFormControl-root, & .MuiAutocomplete-root': {
        height: 'unset',
        width: '40%',
        minWidth: '350px',
        '&.number': {
          width: '20%',
          minWidth: '200px',
        },
      },
      '& .MuiAutocomplete-root .MuiFormControl-root': {
        width: '100%',
      },
    },
  },
}));

// not applicable
// Writer Article Link - Mandatory if status = PM check or Status= PM approved

const defaultValues: any = {
  orderLineStatusUuid: '',
  statusDescription: '',
  quantityWriter: 0,
  pmCleanArticleUrl: '',
  quantityClient: 0,
  pmRevisionComments: '',
  articleTopic: '',
  articleTitle: '',
  keywords: '',
  expertise1Id: 0,
  expertise2Id: 0,
  expertise3Id: 0,
  speakerId: '',
  toneOfVoiceId: 0,
  targetAudienceUuid: '',
  outlineUrl: '',
  exampleArticlesUrl: '',
  internalUrl: '',
  externalUrl: '',
  anchorTextUrl: '',
  anchorText: '',
  hostingSiteUrl: '',
  hasMetaTitle: false,
  hasMetaDescription: false,
  hasImages: false,
  imagesUrl: '',
};

interface ProjectManagerFormProps {
  initialValues: any;
  orderLineDetails: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

function ProjectManagerForm({
  initialValues = defaultValues,
  orderLineDetails,
  onSubmit,
  onCancel,
}: ProjectManagerFormProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const [pmApprovedUuid, setpmApprovedUuid] = useState<string>('');
  const [pmCheckUuid, setpmCheckUuid] = useState<string>('');
  const [needDescriptionUuid, setNeedDescriptionUuid] = useState<string>('');

  const voicesDropdown = useSelector(
    (state: RootState) => state.voices?.voices,
  ).map((voice) => ({ label: voice?.name, value: voice?.id }));

  const speakersDropdown = useSelector(
    (state: RootState) => state.speakers?.speakers,
  ).map((speaker) => ({ label: speaker.name, value: speaker.id }));

  const targetAudienceDropdown = useSelector(
    (state: RootState) => state.targetAudience?.targetAudience,
  ).map((targetAudience: any) => ({
    label: targetAudience.name,
    value: targetAudience.uuid,
  }));

  const expertiseDropdown = useSelector(
    (state: RootState) => state?.expertise?.expertise,
  ).map((expertise: any) => ({
    label: expertise?.name,
    value: expertise.id,
  }));

  const orderLineStatusesDropdown = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const orderLineStatuses = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  );

  const validationSchema = Yup.object().shape({
    orderLineStatusUuid: Yup.string().required('This field is required!'),
    statusDescription: Yup.string().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === needDescriptionUuid,
      then: Yup.string().required('This field is required!').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    quantityWriter: Yup.number().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === pmCheckUuid ||
        orderLineStatusUuid === pmApprovedUuid,
      then: Yup.number().required('This field is required!').nullable(),
      otherwise: Yup.number().nullable(),
    }), // Quantity Writer - Mandatory if status = pm_check or Status = pm_approved
    pmCleanArticleUrl: Yup.string().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === pmApprovedUuid,
      then: Yup.string().required('This field is required!').nullable(),
      otherwise: Yup.string().nullable(),
    }), // PM  Clean Article Link - Mandatory if Status = pm_approved
    quantityClient: Yup.number().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === pmApprovedUuid,
      then: Yup.number().required('This field is required!').nullable(),
      otherwise: Yup.number().nullable(),
    }), // Mandatory if Status= pm_approved
    pmRevisionComments: Yup.string().nullable(), // PM Revision Comments - Non mandatory
    articleTopic: Yup.string().nullable().required('This field is required!'), // Article Topic - Mandatory
    articleTitle: Yup.string().nullable(), // Article Title - Non mandatory
    keywords: Yup.string().nullable(), // Keywords - non mandatory
    expertise1Id: Yup.number().required('This field is required!'), // Expertise 1 - Mandatory
    writerArticleLink: Yup.string().required('This field is required!'), // Expertise 1 - Mandatory
    expertise2Id: Yup.number(), // Expertise 2 - non mandatory
    expertise3Id: Yup.number(), // Expertise 3 - non mandatory
    speakerId: Yup.number(), // Speaker Perspective - non mandatory
    toneOfVoiceId: Yup.number(), // Tone of Voice - non mandatory
    targetAudienceUuid: Yup.string().nullable(), // Target Audience - non mandatory
    outlineUrl: Yup.string().nullable(), // Outline Link - non mandatory
    exampleArticlesUrl: Yup.string().nullable(), // Example Articles Link - non mandatory
    internalUrl: Yup.string().nullable(), // Internal Links - non mandatory
    externalUrl: Yup.string().nullable(), // External Links - non mandatory
    anchorTextUrl: Yup.string().nullable(), // Anchor Text Link - non mandatory
    anchorText: Yup.string().nullable(), // Anchor Text - non mandatory
    hostingSiteUrl: Yup.string().nullable(), // Hosting Site Link - non mandatory
    hasMetaTitle: Yup.bool(),
    hasMetaDescription: Yup.bool(),
    hasImages: Yup.bool(),
    imagesUrl: Yup.string().nullable(), // Images Link - non mandatory
  });

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
      });
    },
  });

  useEffect(() => {
    const pmCheckStatusValue = orderLineStatuses.find(
      (status: any) => status.code === 'pm_check',
    );
    const pmApprovedStatusValue = orderLineStatuses.find(
      (status: any) => status.code === 'pm_approved',
    );

    setpmCheckUuid(pmCheckStatusValue?.uuid);
    setpmApprovedUuid(pmApprovedStatusValue?.uuid);
    if (
      formik?.initialValues?.expertise1Id !== '' &&
      formik.values.expertise1Id === undefined
    ) {
      formik.values.expertise1Id = formik?.initialValues?.expertise1Id;
    }
    const currentStatus = orderLineStatuses.find(
      (status: any) => status.uuid === formik?.values?.orderLineStatusUuid,
    );
    if (currentStatus?.needsDescription) {
      setNeedDescriptionUuid(currentStatus?.uuid);
    }
  }, [formik]);

  useEffect(() => {
    dispatch(voicesActions.getTonesOfVoices());
    dispatch(speakersActions.getSpeakers());
    dispatch(targetAudienceActions.getTargetAudience());
    dispatch(expertiseActions.getExpertise());
    dispatch(orderLineStatusesActions.getOrderLineStatuses());
  }, [dispatch, params.id]);

  const permission = useSelector((state: RootState) => state.auth.permissions);

  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnCancelLabel="Cancel"
      btnSubmitLabel="Save"
      className={classes.formRoot}
      sx={{ padding: '32px', backgroundColor: '#fff' }}>
      <FormInfoRow
        rowTitle={'Client task Instruction link'}
        rowValueText={orderLineDetails?.clientInstructionsUrl}
        url={orderLineDetails?.clientInstructionsUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Client Additional Note'}
        rowValueText={orderLineDetails?.instructions}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Client General Notes Link'}
        rowValueText={orderLineDetails?.order?.contactPerson?.notes}
        url={orderLineDetails?.order?.contactPerson?.notes}
        color={colors.black}
      />
      <Box className="boxRow">
        <FormLabel>Status</FormLabel>
        <TextField
          select
          name="orderLineStatusUuid"
          size="small"
          value={formik?.values?.orderLineStatusUuid}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.orderLineStatusUuid)}
          helperText={formik.errors.orderLineStatusUuid}>
          {createMenuItems(orderLineStatusesDropdown)}
        </TextField>
      </Box>
      <Box className="boxRow">
        <FormLabel>Status Description</FormLabel>
        <TextField
          name="statusDescription"
          size="small"
          fullWidth
          value={formik.values?.statusDescription}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.statusDescription)}
          helperText={formik.errors.statusDescription}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Writer Article Link</FormLabel>
        <TextField
          name="writerArticleLink"
          size="small"
          fullWidth
          value={formik.values?.writerArticleLink}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.writerArticleLink)}
          helperText={formik.errors.writerArticleLink}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Quantity Writer</FormLabel>
        <TextField
          type="number"
          name="quantityWriter"
          size="small"
          className="number"
          fullWidth
          value={formik.values?.quantityWriter}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.quantityWriter)}
          helperText={formik.errors.quantityWriter}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>PM Clean Article Link</FormLabel>
        <TextField
          name="pmCleanArticleUrl"
          size="small"
          fullWidth
          value={formik.values?.pmCleanArticleUrl}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.pmCleanArticleUrl)}
          helperText={formik.errors.pmCleanArticleUrl}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Quantity Client</FormLabel>
        <TextField
          type="number"
          name="quantityClient"
          size="small"
          className="number"
          fullWidth
          value={formik.values?.quantityClient}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.quantityClient)}
          helperText={formik.errors.quantityClient}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>PM Revision Comments</FormLabel>
        <TextField
          name="pmRevisionComments"
          size="small"
          fullWidth
          value={formik.values?.pmRevisionComments}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.pmRevisionComments)}
          helperText={formik.errors.pmRevisionComments}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Article Topic</FormLabel>
        <TextField
          name="articleTopic"
          size="small"
          fullWidth
          value={formik.values?.articleTopic}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.articleTopic)}
          helperText={formik.errors.articleTopic}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Article Title</FormLabel>
        <TextField
          name="articleTitle"
          size="small"
          fullWidth
          value={formik.values?.articleTitle}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.articleTitle)}
          helperText={formik.errors.articleTitle}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Keywords</FormLabel>
        <TextField
          name="keywords"
          size="small"
          fullWidth
          value={formik.values?.keywords}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.keywords)}
          helperText={formik.errors.keywords}
          pressEnter={formik.submitForm}
        />
      </Box>

      <Box className="boxRow">
        <FormLabel>Expertise 1</FormLabel>
        <SuggestedDropdown
          url={Endpoint.Expertise}
          inputId="expertise1Id"
          initialData={expertiseDropdown}
          inputValue={{
            label:
              formik?.values?.expertise1Name || initialValues?.expertise1Name,
            value: formik?.values?.expertise1Id || initialValues?.expertise1Id,
          }}
          dropdownLabel="name"
          dropdownValue="id"
          dataLocation="resultDataItems"
          orderBy="name"
          order="ASC"
          error={Boolean(formik?.errors?.expertise1Id)}
          helperText={formik.errors.expertise1Id}
          formikHook={formik}
          style={{ display: 'flex', flexDirection: 'row' }}
        />
        {/*<TextField
          select
          name="expertise1Id"
          size="small"
          fullWidth
          value={formik?.values?.expertise1Id}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.expertise1Id)}
          helperText={formik.errors.expertise1Id}
          pressEnter={formik.submitForm}>
          {createMenuItems(expertiseDropdown)}
        </TextField>*/}
      </Box>
      <Box className="boxRow">
        <FormLabel>Expertise 2</FormLabel>
        <SuggestedDropdown
          url={Endpoint.Expertise}
          inputId="expertise2Id"
          initialData={expertiseDropdown}
          inputValue={{
            label: formik?.values?.expertise2?.name || '',
            value: formik?.values?.expertise2?.id || '',
          }}
          dropdownLabel="name"
          dropdownValue="id"
          dataLocation="resultDataItems"
          orderBy="name"
          order="ASC"
          formikHook={formik}
          style={{ display: 'flex', flexDirection: 'row' }}
        />
        {/*<TextField
          select
          name="expertise2Id"
          size="small"
          fullWidth
          value={formik.values.expertise2Id}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.expertise2Id)}
          helperText={formik.errors.expertise2Id}
          pressEnter={formik.submitForm}>
          {createMenuItems(expertiseDropdown)}
        </TextField>*/}
      </Box>
      <Box className="boxRow">
        <FormLabel>Expertise 3</FormLabel>
        <SuggestedDropdown
          url={Endpoint.Expertise}
          inputId="expertise3Id"
          initialData={expertiseDropdown}
          inputValue={{
            label: formik?.values?.expertise3?.name || '',
            value: formik?.values?.expertise3?.id || '',
          }}
          dropdownLabel="name"
          dropdownValue="id"
          dataLocation="resultDataItems"
          orderBy="name"
          order="ASC"
          formikHook={formik}
          style={{ display: 'flex', flexDirection: 'row' }}
        />
        {/*<TextField
          select
          name="expertise3Id"
          size="small"
          fullWidth
          value={formik.values.expertise3Id}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.expertise3Id)}
          helperText={formik.errors.expertise3Id}
          pressEnter={formik.submitForm}>
          {createMenuItems(expertiseDropdown)}
        </TextField>*/}
      </Box>
      <Box className="boxRow">
        <FormLabel>Speaker Perspective</FormLabel>
        <TextField
          select
          name="speakerId"
          size="small"
          fullWidth
          value={formik?.values?.speakerId}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.speakerId)}
          helperText={formik.errors.speakerId}
          pressEnter={formik.submitForm}>
          {createMenuItems(speakersDropdown)}
        </TextField>
      </Box>
      <Box className="boxRow">
        <FormLabel>Tone of Voice</FormLabel>
        <TextField
          select
          name="toneOfVoiceId"
          size="small"
          fullWidth
          value={formik?.values?.toneOfVoiceId}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.toneOfVoiceId)}
          helperText={formik.errors.toneOfVoiceId}
          pressEnter={formik.submitForm}>
          {createMenuItems(voicesDropdown)}
        </TextField>
      </Box>
      <Box className="boxRow">
        <FormLabel>Target Audience</FormLabel>
        <TextField
          select
          name="targetAudienceUuid"
          size="small"
          fullWidth
          defaultValue={formik.values?.targetAudienceUuid}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.targetAudienceUuid)}
          helperText={formik.errors.targetAudienceUuid}
          pressEnter={formik.submitForm}>
          {createMenuItems(targetAudienceDropdown)}
        </TextField>
      </Box>
      <Box className="boxRow">
        <FormLabel>Outline Link</FormLabel>
        <TextField
          name="outlineUrl"
          size="small"
          fullWidth
          value={formik.values?.outlineUrl}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.outlineUrl)}
          helperText={formik.errors.outlineUrl}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Example Articles Link</FormLabel>
        <TextField
          name="exampleArticlesUrl"
          size="small"
          fullWidth
          value={formik.values?.exampleArticlesUrl}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.exampleArticlesUrl)}
          helperText={formik.errors.exampleArticlesUrl}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Internal Links</FormLabel>
        <TextField
          name="internalUrl"
          size="small"
          fullWidth
          value={formik.values?.internalUrl}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.internalUrl)}
          helperText={formik.errors.internalUrl}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>External Links</FormLabel>
        <TextField
          name="externalUrl"
          size="small"
          fullWidth
          value={formik.values?.externalUrl}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.externalUrl)}
          helperText={formik.errors.externalUrl}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Anchor Text Link</FormLabel>
        <TextField
          name="anchorTextUrl"
          size="small"
          fullWidth
          value={formik.values?.anchorTextUrl}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.anchorTextUrl)}
          helperText={formik.errors.anchorTextUrl}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Anchor Text</FormLabel>
        <TextField
          name="anchorText"
          size="small"
          fullWidth
          value={formik.values?.anchorText}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.anchorText)}
          helperText={formik.errors.anchorText}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Hosting Site Link</FormLabel>
        <TextField
          name="hostingSiteUrl"
          size="small"
          fullWidth
          value={formik.values?.hostingSiteUrl}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.hostingSiteUrl)}
          helperText={formik.errors.hostingSiteUrl}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Meta title</FormLabel>
        <Switch
          id="hasMetaTitle"
          value={formik?.values?.hasMetaTitle}
          checked={formik?.values?.hasMetaTitle}
          size="small"
          onChange={formik.handleChange}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Meta description</FormLabel>
        <Switch
          id="hasMetaDescription"
          value={formik?.values?.hasMetaDescription}
          checked={formik?.values?.hasMetaDescription}
          size="small"
          onChange={formik.handleChange}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Images</FormLabel>
        <Switch
          id="hasImages"
          value={formik?.values?.hasImages}
          checked={formik?.values?.hasImages}
          size="small"
          onChange={formik.handleChange}
        />
      </Box>
      <Box className="boxRow">
        <FormLabel>Images Link</FormLabel>
        <TextField
          name="imagesUrl"
          size="small"
          fullWidth
          value={formik.values?.imagesUrl}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.imagesUrl)}
          helperText={formik.errors.imagesUrl}
          pressEnter={formik.submitForm}
        />
      </Box>
    </Form>
  );
}

export default memo(ProjectManagerForm);

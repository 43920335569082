import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'components';
import { useDispatch } from 'react-redux';
import { actions as orderListActions } from 'store/slices/orderListSlice';
import l from 'helpers/l';
import OrderListForm from 'components/Forms/OrderListForm';

function OrderListAddPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
    };

    console.log('nova shema', data);

    dispatch(orderListActions.addEntries({ ...data, navigate }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageLayout title={'Client Add Order'} fullWidth={true}>
      <OrderListForm onSubmit={handleSubmit} onCancel={handleCancel} />
    </PageLayout>
  );
}

export default memo(OrderListAddPage);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

export interface Orders {
  orderList: any;
  items: any[];
  meta: PaginationMeta;
}

const initialState: any = {
  orderList: {},
  items: [],
  meta: {},
};

export const orderListSlice = createSlice({
  name: 'orderList',
  initialState,
  reducers: {
    getOrderList: (state) => state,
    getOrderListItem: (state) => state,
    setOrderList: (state, action: PayloadAction<any>) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    setOrderListItem: (state, action: PayloadAction<any>) => {
      state.orderList = action.payload;
    },
    addEntries: (state) => state,
    editEntries: (state) => state,
    deleteEntries: (state) => state,
    sendEntriesFilters: (state) => state,
    cancelAllLines: (state) => state,
  },
});
export const actions: any = orderListSlice.actions;
export default orderListSlice.reducer;

import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Box as MuiBox,
  SwipeableDrawer,
} from '@mui/material';
import {
  AccessTime,
  AttachMoney,
  AvTimer,
  CreditCard,
  CurrencyExchange,
  EmailOutlined,
  HelpOutline,
  KeyboardArrowDown,
  Logout,
  People,
  Public,
  PublishedWithChangesSharp,
  RecordVoiceOver,
  SettingsOutlined,
} from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TopicIcon from '@mui/icons-material/Topic';
import * as styless from './SideNavigationStyles';
import { Box, BoxOfAccordion, styles } from './SideNavigationStyles';
import l from 'helpers/l';
import RouteGuard from 'components/Guards/RouteGuard';
import { actions as commonActions } from 'store/slices/common';
import { useDispatch } from 'react-redux';
import { actions as authActions } from 'store/slices/auth';

interface SideNavigationProps {
  sideNavigationState: boolean;
  sideNavigationStateHandler: (state: boolean) => void;
}

const SideNavigation: FC<SideNavigationProps> = ({
  sideNavigationState,
  sideNavigationStateHandler,
}) => {
  const classes = styles();
  const [expandedOrders, setExpandedOrders] = useState(false);
  const [expandedReports, setExpandedReports] = useState(false);
  const [expandedHR, setExpandedHR] = useState(false);
  const [expandedAR, setExpandedAR] = useState(false);
  const [expandedAP, setExpandedAP] = useState(false);
  const [expandedAdmin, setExpandedAdmin] = useState(false);
  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event?.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      sideNavigationStateHandler(!sideNavigationState);
    };

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authActions.logout());
    dispatch(
      commonActions.snackBar({
        open: true,
        message: l('AUTH.LOGOUT'),
        severity: 'info',
      }),
    );
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={sideNavigationState}
      onClose={toggleDrawer()}
      onOpen={toggleDrawer()}>
      <MuiBox
        sx={{ width: 'auto' }}
        role="presentation"
        onKeyDown={toggleDrawer()}>
        <MuiBox sx={styless.CollapseBoxStyles}>
          {/* ORDERS */}
          <RouteGuard
            allow={[
              'pm_screen_unrestricted',
              'pm_screen',
              'pm_screen_limited',
              'order_receiving_unrestricted',
              'order_receiving',
              'projects',
              'order_delivery_screen_unrestricted',
              'order_delivery_screen',
              'order_delivery_screen_account_manager',
              'editor_screen',
              'writer_screen',
              'administration',
              'client_order_receiving',
            ]}>
            <Accordion
              expanded={expandedOrders}
              elevation={0}
              disableGutters
              square>
              <AccordionSummary
                sx={styless.AccordionSummaryStyles}
                expandIcon={<KeyboardArrowDown sx={styless.IconStyles} />}
                onClick={() => setExpandedOrders(!expandedOrders)}>
                <AccessTime sx={styless.IconStyles} />
                <BoxOfAccordion typography="body1">
                  {l('PAGES.ORDERS')}
                </BoxOfAccordion>
              </AccordionSummary>
              <AccordionDetails sx={styless.AccordionDetailsStyles}>
                <RouteGuard
                  allow={['order_receiving', 'order_receiving_unrestricted']}>
                  <NavLink to={'/orders-entries'} style={styless.LinkStyles}>
                    <Box>
                      <AccessTime sx={styless.IconStyles} />
                      {l('PAGES.ORDER_ENTRY_LIST')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard
                  allow={[
                    'order_receiving',
                    'order_receiving_unrestricted',
                    'client_order_receiving',
                  ]}>
                  <NavLink to={'/order-list'} style={styless.LinkStyles}>
                    <Box>
                      <AccessTime sx={styless.IconStyles} />
                      {'Client Order Entries'}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard
                  allow={[
                    'order_delivery_screen_unrestricted',
                    'order_delivery_screen',
                    'order_delivery_screen_account_manager',
                  ]}>
                  <NavLink to={'/order-delivery'} style={styless.LinkStyles}>
                    <Box>
                      <People sx={styless.IconStyles} />
                      {l('PAGES.ORDER_DELIVERY_SCREEN')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard
                  allow={[
                    'pm_screen_unrestricted',
                    'pm_screen',
                    'pm_screen_limited',
                  ]}>
                  <NavLink to={'/pm-work-screen'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.PROJECT_MANAGER_SCREEN')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['writer_screen']}>
                  <NavLink to={'/writer-screen'} style={styless.LinkStyles}>
                    <Box>
                      <People sx={styless.IconStyles} />
                      {l('PAGES.WRITER_WORK_SCREEN')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['editor_screen']}>
                  <NavLink to={'/editor-screen'} style={styless.LinkStyles}>
                    <Box>
                      <People sx={styless.IconStyles} />
                      {l('PAGES.EDITOR_WORK_SCREEN')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['clients_management']}>
                  <NavLink to={'/clients'} style={styless.LinkStyles}>
                    <Box>
                      <People sx={styless.IconStyles} />
                      {l('PAGES.CLIENTS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['contact_persons_management']}>
                  <NavLink to={'/contact-persons'} style={styless.LinkStyles}>
                    <Box>
                      <People sx={styless.IconStyles} />
                      {l('PAGES.CONTACT_PERSONS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['projects']}>
                  <NavLink to={'/projects'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.PROJECTS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['checklist_management']}>
                  <NavLink to={'/checklists-roles'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.CHECKLISTS_ROLES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['checklist_management']}>
                  <NavLink
                    to={'/checklists-contact-persons'}
                    style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.CHECKLISTS_CONTACT_PERSONS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
              </AccordionDetails>
            </Accordion>
          </RouteGuard>

          {/* HUMAN_RESOURCES */}
          <RouteGuard allow={['human_resources']}>
            <Accordion
              expanded={expandedHR}
              elevation={0}
              disableGutters
              className={classes.AccordionElevation}
              square>
              <AccordionSummary
                sx={styless.AccordionSummaryStyles}
                expandIcon={<KeyboardArrowDown sx={styless.IconStyles} />}
                onClick={() => setExpandedHR(!expandedHR)}>
                <People sx={styless.IconStyles} />
                <BoxOfAccordion typography="body1">
                  {l('PAGES.HUMAN_RESOURCES')}
                </BoxOfAccordion>
              </AccordionSummary>
              <AccordionDetails sx={styless.AccordionDetailsStyles}>
                <RouteGuard allow={['employees_list']}>
                  <NavLink to={'/employees'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.EMPLOYEES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['employee_statuses_management']}>
                  <NavLink to={'/employee-statuses'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.EMPLOYEE_STATUSES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['questions_management']}>
                  <NavLink to={'/questions'} style={styless.LinkStyles}>
                    <Box>
                      <HelpOutline sx={styless.IconStyles} />
                      {l('PAGES.QUESTIONS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
              </AccordionDetails>
            </Accordion>
          </RouteGuard>

          {/* ADMINISTRATION */}
          <RouteGuard allow={['administration']}>
            <Accordion
              className={classes.AccordionElevation}
              expanded={expandedAdmin}
              elevation={0}
              disableGutters
              square>
              <AccordionSummary
                sx={styless.AccordionSummaryStyles}
                expandIcon={<KeyboardArrowDown sx={styless.IconStyles} />}
                onClick={() => setExpandedAdmin(!expandedAdmin)}>
                <SettingsOutlined sx={styless.IconStyles} />
                {/* <MuiBox typography="body1" className={classes.BoxOfAccordion}> */}
                <BoxOfAccordion typography="body1">
                  {l('PAGES.ADMINISTRATION')}
                </BoxOfAccordion>
              </AccordionSummary>
              <AccordionDetails sx={styless.AccordionDetailsStyles}>
                <RouteGuard allow={['article_types_management']}>
                  <NavLink to={'/article-types'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.ARTICLE_TYPES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['checklist_management']}>
                  <NavLink to={'/checklists'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.CHECKLISTS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['order_line_status_management']}>
                  <NavLink to={'/client-statuses'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {'Client Statuses'}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['countries_management']}>
                  <NavLink to="/countries" style={styless.LinkStyles}>
                    <Box>
                      <Public sx={styless.IconStyles} />
                      {l('PAGES.COUNTRIES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['currencies_management']}>
                  <NavLink to="/currencies" style={styless.LinkStyles}>
                    <Box>
                      <CurrencyExchange sx={styless.IconStyles} />
                      {l('PAGES.CURRENCIES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['email_templates_management']}>
                  <NavLink to={'/email-list'} style={styless.LinkStyles}>
                    <Box>
                      <EmailOutlined sx={styless.IconStyles} />
                      {l('PAGES.EMAIL_TEMPLATES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['expertise_management']}>
                  <NavLink to={'/expertise'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.EXPERTISE')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['languages_management']}>
                  <NavLink to={'/languages'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.LANGUAGES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['payment_methods_management']}>
                  <NavLink to={'/payment'} style={styless.LinkStyles}>
                    <Box>
                      <CreditCard sx={styless.IconStyles} />
                      {l('PAGES.PAYMENT_METHODS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['permissions_management']}>
                  <NavLink to={'/permissions'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.PERMISSIONS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['speakers_management']}>
                  <NavLink to={'/speakers'} style={styless.LinkStyles}>
                    <Box>
                      <RecordVoiceOver sx={styless.IconStyles} />
                      {l('PAGES.SPEAKERS')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['roles_management']}>
                  <NavLink to={'/roles'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.ROLES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['target_audience_management']}>
                  <NavLink to={'/target-audience'} style={styless.LinkStyles}>
                    <Box>
                      <People sx={styless.IconStyles} />
                      {l('PAGES.TARGET_AUDIENCE')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['timezones_list']}>
                  <NavLink to={'/timezones'} style={styless.LinkStyles}>
                    <Box>
                      <AvTimer sx={styless.IconStyles} />
                      {l('PAGES.TIME_ZONES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['tones_of_voice_management']}>
                  <NavLink to={'/tone-of-voices'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.TONE_VOICES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['order_types_management']}>
                  <NavLink to={'/order-types'} style={styless.LinkStyles}>
                    <Box>
                      <SettingsOutlined sx={styless.IconStyles} />
                      {l('PAGES.ORDER_TYPES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['order_line_status_management']}>
                  <NavLink
                    to={'/order-line-statuses'}
                    style={styless.LinkStyles}>
                    <Box>
                      <PublishedWithChangesSharp sx={styless.IconStyles} />
                      {l('PAGES.ORDER_LINE_STATUSES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
              </AccordionDetails>
            </Accordion>
          </RouteGuard>
          {/* ACCOUNTS_PAYABLE */}
          <RouteGuard
            allow={[
              'ap_employee_language_prices_management',
              'ap_employee_project_prices_management',
              'ap_language_prices_management',
            ]}>
            <Accordion
              expanded={expandedAP}
              elevation={0}
              disableGutters
              className={classes.AccordionElevation}
              square>
              <AccordionSummary
                sx={styless.AccordionSummaryStyles}
                expandIcon={<KeyboardArrowDown sx={styless.IconStyles} />}
                onClick={() => setExpandedAP(!expandedAP)}>
                <AttachMoney sx={styless.IconStyles} />
                <BoxOfAccordion typography="body1">
                  {l('PAGES.ACCOUNTS_PAYABLE')}
                </BoxOfAccordion>
              </AccordionSummary>
              <AccordionDetails sx={styless.AccordionDetailsStyles}>
                <RouteGuard allow={['ar_language_prices_management']}>
                  <NavLink to={'/ap-language-rates'} style={styless.LinkStyles}>
                    <Box>
                      <AttachMoney sx={styless.IconStyles} />
                      {l('PAGES.AP_LANGUAGE_RATES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['ar_project_prices_management']}>
                  <NavLink
                    to={'/ap-employee-language-rates'}
                    style={styless.LinkStyles}>
                    <Box>
                      <AttachMoney sx={styless.IconStyles} />
                      {l('PAGES.AP_EMPLOYEE_LANGUAGE_RATES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['ar_client_prices_management']}>
                  <NavLink
                    to={'/ap-employee-project-rates'}
                    style={styless.LinkStyles}>
                    <Box>
                      <AttachMoney sx={styless.IconStyles} />
                      {l('PAGES.AP_EMPLOYEE_PROJECT_RATES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
              </AccordionDetails>
            </Accordion>
          </RouteGuard>

          {/* ACCOUNTS_RECEIVABLE */}
          <RouteGuard
            allow={[
              'ar_language_prices_management',
              'ar_project_prices_management',
              'ar_client_prices_management',
            ]}>
            <Accordion
              expanded={expandedAR}
              elevation={0}
              disableGutters
              className={classes.AccordionElevation}
              square>
              <AccordionSummary
                sx={styless.AccordionSummaryStyles}
                expandIcon={<KeyboardArrowDown sx={styless.IconStyles} />}
                onClick={() => setExpandedAR(!expandedAR)}>
                <AttachMoney sx={styless.IconStyles} />
                <BoxOfAccordion typography="body1">
                  {l('PAGES.ACCOUNTS_RECEIVABLE')}
                </BoxOfAccordion>
              </AccordionSummary>
              <AccordionDetails sx={styless.AccordionDetailsStyles}>
                <RouteGuard allow={['ar_language_prices_management']}>
                  <NavLink to={'/language-rates'} style={styless.LinkStyles}>
                    <Box>
                      <AttachMoney sx={styless.IconStyles} />
                      {l('PAGES.LANGUAGE_RATES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['ar_project_prices_management']}>
                  <NavLink to={'/project-rates'} style={styless.LinkStyles}>
                    <Box>
                      <AttachMoney sx={styless.IconStyles} />
                      {l('PAGES.PROJECT_RATES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
                <RouteGuard allow={['ar_client_prices_management']}>
                  <NavLink to={'/client-rates'} style={styless.LinkStyles}>
                    <Box>
                      <AttachMoney sx={styless.IconStyles} />
                      {l('PAGES.CLIENT_RATES')}
                    </Box>
                  </NavLink>
                </RouteGuard>
              </AccordionDetails>
            </Accordion>
          </RouteGuard>
          <RouteGuard allow={['report_builder', 'report_consumer']}>
            <Accordion
              expanded={expandedReports}
              elevation={0}
              disableGutters
              square
              sx={styless.AccordionStyles}>
              <AccordionSummary
                sx={styless.AccordionSummaryStyles}
                expandIcon={<KeyboardArrowDown sx={styless.IconStyles} />}
                onClick={() => setExpandedReports(!expandedReports)}>
                <TopicIcon sx={styless.IconStyles} />
                {/* <MuiBox typography="body1" className={classes.BoxOfAccordion}> */}
                <BoxOfAccordion typography="body1">
                  {l('PAGES.REPORTS')}
                </BoxOfAccordion>
              </AccordionSummary>
              <RouteGuard allow={['report_builder']}>
                <AccordionDetails sx={styless.AccordionDetailsStyles}>
                  <NavLink to={'/report-parameters'} style={styless.LinkStyles}>
                    <Box>
                      <AssignmentIcon sx={styless.IconStyles} />
                      {l('PAGES.REPORT_PARAMETERS')}
                    </Box>
                  </NavLink>
                </AccordionDetails>
                <AccordionDetails sx={styless.AccordionDetailsStyles}>
                  <NavLink to={'/report-categories'} style={styless.LinkStyles}>
                    <Box>
                      <AssignmentIcon sx={styless.IconStyles} />
                      {l('PAGES.REPORT_CATEGORIES')}
                    </Box>
                  </NavLink>
                </AccordionDetails>
              </RouteGuard>
              <RouteGuard allow={['report_consumer']}>
                <AccordionDetails sx={styless.AccordionDetailsStyles}>
                  <NavLink to={'/reports'} style={styless.LinkStyles}>
                    <Box>
                      <AssignmentIcon sx={styless.IconStyles} />
                      {l('PAGES.REPORTS')}
                    </Box>
                  </NavLink>
                </AccordionDetails>
              </RouteGuard>
            </Accordion>
          </RouteGuard>
          <NavLink onClick={handleLogout} to="" style={styless.LinkStyles}>
            <Box>
              <Logout sx={styless.IconStyles} />
              {l('UI_LABELS.LOG_OUT')}
            </Box>
          </NavLink>
        </MuiBox>
      </MuiBox>
    </SwipeableDrawer>
  );
};

export default SideNavigation;

import React, { FC, memo, useCallback, useEffect } from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import l from 'helpers/l';
import EditTable from 'components/Table/EditTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions as clientStatusesActions } from 'store/slices/clientStatusesSlice';
import { ModalType } from 'enums/ModalEnums';
import { actions as commonActions } from 'store/slices/common';
import { GridRowParams } from '@mui/x-data-grid';

const ClientStatuses: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const clientStatuses = useSelector(
    (state: RootState) => state.clientStatuses.clientStatuses,
  );

  const addEntryHandler = useCallback(() => {
    dispatch(commonActions.showModal({ type: ModalType.ClientStatusAdd }));
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ClientStatusEdit,
          data: params.row.name.toString(),
        }),
      );
    },
    [dispatch],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ClientStatusDelete,
          data: params.row.name.toString(),
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(clientStatusesActions.getClientStatuses());
  }, [dispatch]);
  return (
    <PageLayout title={'Client Statuses'}>
      <EditTable
        getRowId={(row) => row.id}
        loading={isLoading}
        columns={[
          { field: 'id', headerName: 'Id' },
          {
            field: 'name',
            headerName: l('NAME'),
            width: 300,
          },
        ]}
        rows={clientStatuses}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        hideFooterPagination
        hideFooter
      />
    </PageLayout>
  );
};

export default memo(ClientStatuses);

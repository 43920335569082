import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';

export interface ClientStatuses {
  clientStatuses: GenericIdNameType[];
}

const initialState: ClientStatuses = {
  clientStatuses: [],
};

export const clientStatusesSlice = createSlice({
  name: 'clientStatuses',
  initialState,
  reducers: {
    getClientStatuses: (state) => {
      return state;
    },
    setClientStatuses: (state, action: PayloadAction<GenericIdNameType[]>) => {
      state.clientStatuses = action.payload;
    },
    addClientStatuses: (state) => state,
    deleteClientStatuses: (state) => state,
    editClientStatuses: (state) => state,
  },
});
export const actions: any = clientStatusesSlice.actions;
export default clientStatusesSlice.reducer;

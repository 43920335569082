import { FC, memo, useCallback } from 'react';
import { actions as clientsRateActions } from 'store/slices/clientsRateSlice';
import { actions } from 'store/slices/common';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ARClientRateForm from 'components/Forms/ARClientRateForm';

const ClientsRateEditModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    data?.id
      ? state.clientsRate.items.find((entry) => entry?.uuid === data?.id)
      : null,
  );

  const initialValues = {
    sourceLanguage: entry?.sourceLanguage?.name,
    sourceLanguageUuid: entry?.sourceLanguage?.uuid,
    language: entry?.language?.name,
    languageUuid: entry?.language?.uuid,
    contactPersonUuid: entry?.contactPerson?.uuid,
    contactPerson: entry?.contactPerson,
    ratePerWord: entry?.ratePerWord,
    ratePerArticle: entry?.ratePerArticle,
  };

  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        clientsRateActions.editClientsRate({
          urlSuffix: entry?.uuid,
          filters: data?.filters,
          ...values,
        }),
      ),
    [dispatch, entry?.uuid, data],
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <ARClientRateForm
      onSubmit={submitHandler}
      title="Edit Client Rate"
      onClose={closeHandler}
      initialValues={initialValues}
    />
  );
};

export default memo(ClientsRateEditModal);

import { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Stack, Switch, Typography } from '@mui/material';
import { Form, TextField } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import InputRowWrapper from 'components/InputRowWrapper/InputRowWrapper';
import l from 'helpers/l';

const defaultValues: any = {
  // client: '',
  // contactPersonUuid: '',
  // accountManagerUuid: '',
  orderDate: '',
  dueDatetime: '',
  isCorrection: false,
  isRatePerArticleClient: false,
  comments: '',
};

const validationSchema = Yup.object().shape({
  // client: Yup.string(),
  // contactPersonUuid: Yup.string().required('Field is required!'),
  // accountManager: Yup.string().required('Field is required!'),
  orderDate: Yup.date(),
  dueDate: Yup.date(),
  isCorrection: Yup.bool(),
  isRatePerArticleClient: Yup.bool(),
  comments: Yup.string(),
});

type IOrderListForm = {
  initialValues?: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const OrderListForm: FC<IOrderListForm> = ({
  initialValues = defaultValues,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const [orderDateFormat, setOrderDateFormat] = useState<any>(
    initialValues?.orderDate
      ? moment(initialValues?.orderDate).format('DD.MM.YYYY')
      : moment().format('DD.MM.YYYY'),
  );

  const [dueDateFormat, setDueDateFormat] = useState<any>(
    initialValues?.dueDate
      ? moment(initialValues?.dueDate).format('DD.MM.YYYY')
      : moment().format('DD.MM.YYYY'),
  );

  const currentEmployee = useSelector(
    (state: RootState) => state.auth.user.employee,
  );
  const contactPerson = currentEmployee?.contactPerson;
  const accountManager = currentEmployee?.contactPerson?.accountManager;

  console.log('accountManager', accountManager);

  console.log('contactPerson', contactPerson);

  // const contactPersonDropdown = useSelector(
  //   (state: RootState) => state.contactPersons.items,
  // ).map((person) => ({
  //   label: person.nick,
  //   value: person.uuid,
  // }));

  // const accountManagerDropdown = useSelector(
  //   (state: RootState) => state.employees.employeesByRole,
  // ).map((emp: any) => ({
  //   label: `${emp.firstName} ${emp.lastName}`,
  //   value: emp.uuid,
  // }));

  const dateFormatter = (string: any) => {
    const splitDate = string.split('.');
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  };

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
        // orderTypeId: formik?.values?.orderTypeId || null,
        dueDatetime: setTime(new Date(dateFormatter(dueDateFormat))),
        orderedAt: setTime(new Date(dateFormatter(orderDateFormat))),
      });
    },
  });

  console.log('');

  const setTime = (date: any) => {
    const split = String(date).split(' ');
    split[4] = '23:59:00';

    return split.join(' ');
  };

  const handleDueDateChange = (value: any, inputValue: any) => {
    setDueDateFormat(inputValue);
  };

  const handleOrderDateChange = (value: any, inputValue: any) => {
    setOrderDateFormat(inputValue);
  };

  useEffect(() => {
    // dispatch(clientsActions.getClients());
    // dispatch(contactPersonActions.getContactPersonsList());
    // dispatch(employeeActions.getEmployeesByRole({ urlSuffix: 'am' }));
  }, [dispatch]);

  const datePickerStyles = {
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '12px',
    width: '100%',
  };

  const minimalDate = new Date();

  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnCancelLabel="Cancel"
      btnSubmitLabel="Save"
      sx={{ padding: '32px' }}>
      <Stack gap={2} sx={{ marginBottom: '24px' }}>
        <Stack direction="row" gap={1}>
          <Typography>{l('CONTACT_PERSON')}:</Typography>
          <Typography variant="body1">
            {contactPerson
              ? `${contactPerson.firstName} ${contactPerson.lastName}`
              : l('No Contact Person')}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Typography>{l('Account Manager')}:</Typography>
          <Typography variant="body1">
            {accountManager
              ? `${accountManager.firstName} ${accountManager.lastName} `
              : l('No Account Manager')}
          </Typography>
        </Stack>
      </Stack>
      <InputRowWrapper
        label={l('ORDER_DATE')}
        labelWidth="250px"
        inputWidth="35%">
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <KeyboardDatePicker
            autoOk={true}
            showTodayButton={true}
            value={orderDateFormat}
            inputValue={orderDateFormat}
            onChange={handleOrderDateChange}
            format="DD.MM.YYYY"
            style={datePickerStyles}
          />
        </MuiPickersUtilsProvider>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_DUE_DATE')}
        labelWidth="250px"
        inputWidth="35%">
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <KeyboardDatePicker
            autoOk
            showTodayButton
            value={dueDateFormat}
            inputValue={dueDateFormat}
            onChange={handleDueDateChange}
            format="DD.MM.YYYY"
            style={datePickerStyles}
            minDate={minimalDate}
          />
        </MuiPickersUtilsProvider>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>

      <InputRowWrapper
        label={l('CORRECTION')}
        labelWidth="250px"
        inputWidth="35%">
        <Switch
          id="isCorrection"
          value={formik?.values?.isCorrection}
          defaultChecked={initialValues.isCorrection}
          size="small"
          onChange={formik.handleChange}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_RATE_CLIENT')}
        labelWidth="250px"
        inputWidth="35%">
        <Switch
          id="isRatePerArticleClient"
          value={formik?.values?.isRatePerArticleClient}
          defaultChecked={initialValues.isRatePerArticleClient}
          size="small"
          onChange={formik.handleChange}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('COMMENTS')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          name="comments"
          fullWidth
          size="small"
          value={formik?.values?.comments}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.comments)}
          helperText={formik.errors.comments}
        />
      </InputRowWrapper>
    </Form>
  );
};

export default memo(OrderListForm);

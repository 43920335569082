import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as orderListActions } from 'store/slices/orderListSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  getOrderListApi,
  getOrderListItemApi,
  addOrderListApi,
  editOrderListApi,
  deleteOrderListApi,
  sendOrderListFiltersApi,
  cancelAllOrderLinesApi,
} from 'store/api/orderListAPI';
import { APIResult } from 'types/APITypes';
import { genericErrorMessageHelper } from 'helpers/messageHelpers';
import { getOrderLinesByOrderApi } from '../api/orderLinesAPI';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';

function* getOrderList(action: any) {
  yield put(commonActions.setLoading(true));
  console.log('action', action);
  const { result }: APIResult<any> = yield call(
    getOrderListApi,
    action.payload,
  );
  console.log('test', result);
  if (result?.data) {
    yield put(orderListActions.setOrderList(result?.data));
  }
  yield put(commonActions.setLoading(false));
}
function* getOrderListItem(action: any) {
  const urlSuffix = action.payload.data.uuid;

  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(getOrderListItemApi, urlSuffix);
  if (result?.data) {
    yield put(orderListActions.setOrderListItem(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addEntries(action: any) {
  const data = action.payload;
  console.log('data saga new payload ', data);
  const { error }: APIResult<any> = yield call(addOrderListApi, data);

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getOrderListApi);
    if (result?.data) {
      yield put(orderListActions.setOrderList(result?.data));
      data.navigate('/order-list');
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added an order`,
        severity: 'success',
      }),
    );
  }

  yield put(commonActions.setLoading(false));
}

function* editEntries(action: any) {
  const urlSuffix = action.payload.uuid;
  const data = action.payload;

  const { error }: APIResult<any> = yield call(
    editOrderListApi,
    urlSuffix,
    data,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getOrderListApi);
    if (result?.data) {
      yield put(orderListActions.setOrderList(result?.data));
      data.navigate('/order-list');
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited an order entry in the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* cancelAllLines(action: any) {
  const urlSuffix = action.payload;
  const { result, error }: APIResult<never> = yield call(
    cancelAllOrderLinesApi,
    urlSuffix,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(
      getOrderLinesByOrderApi,
      urlSuffix,
    );
    if (result?.data) {
      yield put(orderLinesActions.setOrderLines(result?.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully canceled all order lines!`,
        severity: 'info',
      }),
    );
  }
  yield put(commonActions.hideModal());
  yield put(commonActions.setLoading(false));
}

function* deleteEntries(action: any) {
  const payload = action.payload;
  const urlSuffix = payload.urlSuffix;
  const navigation = payload.navigator;
  const { result, error }: APIResult<never> = yield call(
    deleteOrderListApi,
    urlSuffix,
  );

  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Cannot delete order due to existing relations.',
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
  } else if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getOrderListApi);
    if (result?.data) {
      yield put(orderListActions.setOrderList(result?.data));
    }
    navigation('/order-list');
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted an order from the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* sendEntriesFilters(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendOrderListFiltersApi, params);

  if (result?.data) {
    yield put(orderListActions.setOrderList(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* OrderListWatcher() {
  yield takeLatest(orderListActions.getOrderList.type, getOrderList);
  yield takeLatest(orderListActions.getOrderListItem.type, getOrderListItem);
  yield takeLatest(orderListActions.addEntries.type, addEntries);
  yield takeLatest(orderListActions.editEntries.type, editEntries);
  yield takeLatest(orderListActions.deleteEntries.type, deleteEntries);
  yield takeLatest(
    orderListActions.sendEntriesFilters.type,
    sendEntriesFilters,
  );
  yield takeLatest(orderListActions.cancelAllLines.type, cancelAllLines);
}

import { FC, memo } from 'react';
import { ModalType } from 'enums/ModalEnums';
import { GenericModalProps } from 'types/genericTypes';
import { ModalValueType } from 'types/commonSliceTypes';
import LanguagesAddModal from './languages/LanguagesAddModal';
import LanguagesEditModal from './languages/LanguagesEditModal';
import LanguagesDeleteModal from './languages/LanguagesDeleteModal';
import ContactPersonDeleteModal from './contactPerson/ContactPersonDelete';
import PermissionsEditModal from './permissions/PermissionsEditModal';
import CurrenciesAddModal from './currencies/CurrenciesAddModal';
import CurrenciesEditModal from './currencies/CurrenciesEditModal';
import CurrenciesDeleteModal from './currencies/CurrenciesDeleteModal';
import CountriesEditModal from './countries/CountriesEditModal';
import CountriesAddModal from './countries/CountriesAddModal';
import CountriesDeleteModal from './countries/CountriesDeleteModal';
import RoleEditModal from './roles/RolesEditModal';
import RoleAddModal from './roles/RolesAddModal';
import RoleDeleteModal from './roles/RolesDeleteModal';
import EmployeeStatusesAddModal from './employeeStatuses/EmployeeStatusesAddModal';
import EmployeeStatusesDeleteModal from './employeeStatuses/EmployeeStatusesDeleteModal';
import EmployeeStatusesEditModal from './employeeStatuses/EmployeeStatusesEditModal';
import EmployeesCreateModal from './employees/EmployeesCreateModal';
import EmployeesDeleteModal from './employees/EmployeesDeleteModal';
import EmployeePreferenceModalAdd from './employeePreferenceModal/EmployeePreferenceModalAdd';
import EmployeePreferenceModalDelete from './employeePreferenceModal/EmployeePreferenceModalDelete';
import EmployeePreferenceModalEdit from './employeePreferenceModal/EmployeePreferenceModalEdit';
import ExpertiseAddModal from './expertise/ExpertiseAddModal';
import ExpertiseDeleteModal from './expertise/ExpertiseDeleteModal';
import ExpertiseEditModal from './expertise/ExpertiseEditModal';
import QuestionsAddModal from './questions/QuestionsAddModal';
import QuestionsDeleteModal from './questions/QuestionsDeleteModal';
import QuestionsEditModal from './questions/QuestionsEditModal';
import VoiceAddModal from './toneOfVoices/VoiceAddModal';
import VoiceDeleteModal from './toneOfVoices/VoiceDeleteModal';
import VoiceEditModal from './toneOfVoices/VoiceEditModal';
import PaymentAddModal from './payment/PaymentAddModal';
import PaymentDeleteModal from './payment/PaymentDeleteModal';
import PaymentEditModal from './payment/PaymentEditModal';
import ArticleTypeAddModal from './articleTypes/ArticleTypeAddModal';
import ArticleTypeDeleteModal from './articleTypes/ArticleTypeDeleteModal';
import ArticleTypeEditModal from './articleTypes/ArticleTypeEditModal';
import OrderTypeAddModal from './orderTypes/OrderTypeAddModal';
import OrderTypeDeleteModal from './orderTypes/OrderTypeDeleteModal';
import OrderTypeEditModal from './orderTypes/OrderTypeEditModal';
import OrderEntriesDeleteModal from './orderEntries/OrderEntriesCancelDeleteModal';
import OrderEntryLineDeleteModal from './orderEntryLine/OrderEntryLineDeleteModal';
import OrderLineStatusesAddModal from './orderLineStatuses/OrderLineStatusesAddModal';
import OrderLineStatusesDeleteModal from './orderLineStatuses/OrderLineStatusesDeleteModal';
import OrderLineStatusesEditModal from './orderLineStatuses/OrderLineStatusesEditModal';
import ChecklistsAddModal from './checklists/ChecklistsAddModal';
import ChecklistsDeleteModal from './checklists/ChecklistsDeleteModal';
import ChecklistsEditModal from './checklists/ChecklistsEditModal';
import ChecklistsRolesAddModal from './checklistsRoles/ChecklistsRolesAddModal';
import ChecklistsRolesDeleteModal from './checklistsRoles/ChecklistsRolesDeleteModal';
import ChecklistsRolesEditModal from './checklistsRoles/ChecklistsRolesEditModal';
import ChecklistsContactPersonsAddModal from './checklistsContactPersons/ChecklistsContactPersonsAddModal';
import ChecklistsContactPersonsDeleteModal from './checklistsContactPersons/ChecklistsContactPersonsDeleteModal';
import ChecklistsContactPersonsEditModal from './checklistsContactPersons/ChecklistsContactPersonsEditModal';
import EmployeeViewRolesAddModal from './employeeViewRoles/EmployeeRoleAdd';
import EmployeeViewRolesEditModal from './employeeViewRoles/EmployeeRoleEdit';
import EmployeeRolesDeleteModal from './employeeViewRoles/EmployeeRoleDelete';
import SpeakersAddModal from './speakers/SpeakersAddModal';
import SpeakersDeleteModal from './speakers/SpeakersDeleteModal';
import SpeakersEditModal from './speakers/SpeakersEditModal';
import ReplacementsAddModal from './replacements/ReplacementsAddModal';
import ReplacementsDeleteModal from './replacements/ReplacementsDeleteModal';
import UserLanguageAddModal from './userLanguages/UserLanguageAddModal';
import UserLanguageDeleteModal from './userLanguages/UserLanguageDeleteModal';
import UserExpertiseAddModal from './userExpertise/UserExpertiseAddModal';
import UserExpertiseDeleteModal from './userExpertise/UserExpertiseDeleteModal';
import UserExpertiseEditModal from './userExpertise/UserExpertiseEditModal';
import UserQuestionAddModal from './userQuestions/UserQuestionAddModal';
import UserQuestionDeleteModal from './userQuestions/UserQuestionDeleteModal';
import UserQuestionEditModal from './userQuestions/UserQuestionEditModal';
import ClientsDeleteModal from './clients/ClientsDelete';
import TargetAudienceAddModal from './targetAudience/TargetAudienceAddModal';
import TargetAudienceDeleteModal from './targetAudience/TargetAudienceDeleteModal';
import TargetAudienceEditModal from './targetAudience/TargetAudienceEditModal';
import ProjectsAdd from './projects/ProjectsAdd';
import ProjectsDelete from './projects/ProjectsDelete';
import ProjectsEdit from './projects/ProjectsEdit';
import PmCheckListModal from './pmCheckList/PmCheckListModal';
import StatusHistoryModal from './statusHistory/StatusHistoryModal';
import AssignAssistantPMModal from './assistantPM/AssignAssistantPMModal';
import EditorModal from './editorModal/EditorModal';
import OrderDeliveryEditModal from './orderDelivery/OrderDeliveryEditModal';
import DuplicateOrderLineModal from './duplicateOrderLine/DuplicateOrderLineModal';
import ReportParametersModal from './reports/ReportParametersModal';
import ReportParametersDeleteModal from './reports/ReportParametersDeleteModal';
import ReportParametersEditModal from './reports/ReportParametersEditModal';
import ReportCategoryAddModal from './reports/reportCategories/ReportCategoryAddModal';
import ReportCategoryDeleteModal from './reports/reportCategories/ReportCategoryDeleteModal';
import LanguageRatesAddModal from './arLanguageRates/LanguageRatesAddModal';
import LanguageRatesEditModal from './arLanguageRates/LanguageRatesEditModal';
import LanguageRatesDeleteModal from './arLanguageRates/LanguageRatesDeleteModal';
import ProjectsRatesAddModal from './arProjectsRates/ProjectsRatesAddModal';
import ProjectsRatesEditModal from './arProjectsRates/ProjectsRatesEditModal';
import ProjectsRatesDeleteModal from './arProjectsRates/ProjectsRatesDeleteModal';
import ClientsRatesAddModal from './arClientsRates/ClientsRateAddModal';
import ClientsRatesEditModal from './arClientsRates/ClientsRateEditModal';
import ClientsRatesDeleteModal from './arClientsRates/ClientsRateDeleteModal';
import APLanguageRateAddModal from './apLanguageRate/APLanguageRateAddModal';
import APLanguageRateEditModal from './apLanguageRate/APLanguageRateEditModal';
import APLanguageRateDeleteModal from './apLanguageRate/APLanguageRateDeleteModal';
import ChangeUserNameModal from './employees/ChangeUserNameModal';
import APEmployeeLanguageRateAddModal from './apEmployeeLanguageRate/APEmployeeLanguageRateAddModal';
import APEmployeeLanguageRateEditModal from './apEmployeeLanguageRate/APEmployeeLanguageRateEditModal';
import APEmployeeLanguageRateDeleteModal from './apEmployeeLanguageRate/APEmployeeLanguageRateDeleteModal';
import APEmployeeProjectRateAddModal from './apEmployeeProjectRate/APEmployeeProjectRateAddModal';
import APEmployeeProjectRateEditModal from './apEmployeeProjectRate/APEmployeeProjectRateEditModal';
import APEmployeeProjectRateDeleteModal from './apEmployeeProjectRate/APEmployeeProjectRateDeleteModal';
import UserLanguageEditModal from './userLanguages/UserLanaguageEditModal';
import ClientStatusesAddModal from './clientStatuses/ClientStatusesAddModal';
import ClientStatusesEditModal from './clientStatuses/ClientStatusesEditModal';
import ClientStatusesDeleteModal from './clientStatuses/ClientStatusesDeleteModal';

type ModalContentProps = {
  type: ModalValueType;
} & GenericModalProps;

const ModalContent: FC<ModalContentProps> = ({ type, ...rest }) => {
  switch (type) {
    case ModalType.LanguageAdd: {
      return <LanguagesAddModal {...rest} />;
    }

    case ModalType.LanguageEdit: {
      return <LanguagesEditModal {...rest} />;
    }

    case ModalType.LanguageDelete: {
      return <LanguagesDeleteModal {...rest} />;
    }

    case ModalType.PermissionsEdit: {
      return <PermissionsEditModal {...rest} />;
    }

    case ModalType.CurrenciesAdd: {
      return <CurrenciesAddModal {...rest} />;
    }

    case ModalType.CurrenciesEdit: {
      return <CurrenciesEditModal {...rest} />;
    }

    case ModalType.CurrenciesDelete: {
      return <CurrenciesDeleteModal {...rest} />;
    }

    case ModalType.CountriesAdd: {
      return <CountriesAddModal {...rest} />;
    }
    case ModalType.CountriesEdit: {
      return <CountriesEditModal {...rest} />;
    }
    case ModalType.CountriesDelete: {
      return <CountriesDeleteModal {...rest} />;
    }

    case ModalType.RoleEdit: {
      return <RoleEditModal {...rest} />;
    }

    case ModalType.RoleAdd: {
      return <RoleAddModal {...rest} />;
    }

    case ModalType.RoleDelete: {
      return <RoleDeleteModal {...rest} />;
    }

    case ModalType.EmployeeStatusesAdd: {
      return <EmployeeStatusesAddModal {...rest} />;
    }

    case ModalType.EmployeeStatusesDelete: {
      return <EmployeeStatusesDeleteModal {...rest} />;
    }

    case ModalType.EmployeeStatusesEdit: {
      return <EmployeeStatusesEditModal {...rest} />;
    }

    case ModalType.EmployeesCreate: {
      return <EmployeesCreateModal {...rest} />;
    }
    case ModalType.EmployeesDelete: {
      return <EmployeesDeleteModal {...rest} />;
    }
    case ModalType.ExpertiseAdd: {
      return <ExpertiseAddModal {...rest} />;
    }

    case ModalType.ExpertiseEdit: {
      return <ExpertiseEditModal {...rest} />;
    }

    case ModalType.ExpertiseDelete: {
      return <ExpertiseDeleteModal {...rest} />;
    }

    case ModalType.QuestionsAdd: {
      return <QuestionsAddModal {...rest} />;
    }

    case ModalType.QuestionsEdit: {
      return <QuestionsEditModal {...rest} />;
    }

    case ModalType.QuestionsDelete: {
      return <QuestionsDeleteModal {...rest} />;
    }

    case ModalType.VoiceAdd: {
      return <VoiceAddModal {...rest} />;
    }

    case ModalType.VoiceEdit: {
      return <VoiceEditModal {...rest} />;
    }

    case ModalType.VoiceDelete: {
      return <VoiceDeleteModal {...rest} />;
    }
    case ModalType.PaymentAdd: {
      return <PaymentAddModal {...rest} />;
    }

    case ModalType.PaymentEdit: {
      return <PaymentEditModal {...rest} />;
    }

    case ModalType.PaymentDelete: {
      return <PaymentDeleteModal {...rest} />;
    }

    case ModalType.ArticleTypesAdd: {
      return <ArticleTypeAddModal {...rest} />;
    }

    case ModalType.ArticleTypesEdit: {
      return <ArticleTypeEditModal {...rest} />;
    }

    case ModalType.ArticleTypesDelete: {
      return <ArticleTypeDeleteModal {...rest} />;
    }

    case ModalType.OrderTypesAdd: {
      return <OrderTypeAddModal {...rest} />;
    }

    case ModalType.OrderTypesEdit: {
      return <OrderTypeEditModal {...rest} />;
    }

    case ModalType.OrderTypesDelete: {
      return <OrderTypeDeleteModal {...rest} />;
    }

    case ModalType.ClientStatusAdd: {
      return <ClientStatusesAddModal {...rest} />;
    }

    case ModalType.ClientStatusEdit: {
      return <ClientStatusesEditModal {...rest} />;
    }

    case ModalType.ClientStatusDelete: {
      return <ClientStatusesDeleteModal {...rest} />;
    }

    case ModalType.OrderEntriesDelete: {
      return <OrderEntriesDeleteModal {...rest} />;
    }
    case ModalType.OrderLineStatusesAdd: {
      return <OrderLineStatusesAddModal {...rest} />;
    }

    case ModalType.OrderLineStatusesDelete: {
      return <OrderLineStatusesDeleteModal {...rest} />;
    }

    case ModalType.OrderLineStatusesEdit: {
      return <OrderLineStatusesEditModal {...rest} />;
    }

    case ModalType.OrderEntryLineDeleteModal: {
      return <OrderEntryLineDeleteModal {...rest} />;
    }

    case ModalType.EmployeeRolesAdd: {
      return <EmployeeViewRolesAddModal {...rest} />;
    }

    case ModalType.EmployeeRolesEdit: {
      return <EmployeeViewRolesEditModal {...rest} />;
    }

    case ModalType.EmployeeRolesDelete: {
      return <EmployeeRolesDeleteModal {...rest} />;
    }
    case ModalType.EmployeePreferenceAdd: {
      return <EmployeePreferenceModalAdd {...rest} />;
    }
    case ModalType.EmployeePreferenceDelete: {
      return <EmployeePreferenceModalDelete {...rest} />;
    }
    case ModalType.EmployeePreferenceEdit: {
      return <EmployeePreferenceModalEdit {...rest} />;
    }
    case ModalType.ChecklistsAdd: {
      return <ChecklistsAddModal {...rest} />;
    }

    case ModalType.ChecklistsEdit: {
      return <ChecklistsEditModal {...rest} />;
    }

    case ModalType.ChecklistsDelete: {
      return <ChecklistsDeleteModal {...rest} />;
    }

    case ModalType.ChecklistsRolesAdd: {
      return <ChecklistsRolesAddModal {...rest} />;
    }

    case ModalType.ChecklistsRolesEdit: {
      return <ChecklistsRolesEditModal {...rest} />;
    }

    case ModalType.ChecklistsRolesDelete: {
      return <ChecklistsRolesDeleteModal {...rest} />;
    }

    case ModalType.ChecklistsContactPersonsAdd: {
      return <ChecklistsContactPersonsAddModal {...rest} />;
    }

    case ModalType.ChecklistsContactPersonsEdit: {
      return <ChecklistsContactPersonsEditModal {...rest} />;
    }

    case ModalType.ChecklistsContactPersonsDelete: {
      return <ChecklistsContactPersonsDeleteModal {...rest} />;
    }

    case ModalType.SpeakersAdd: {
      return <SpeakersAddModal {...rest} />;
    }

    case ModalType.SpeakersEdit: {
      return <SpeakersEditModal {...rest} />;
    }

    case ModalType.SpeakersDelete: {
      return <SpeakersDeleteModal {...rest} />;
    }

    case ModalType.ReplacementsAdd: {
      return <ReplacementsAddModal {...rest} />;
    }

    case ModalType.ReplacementsDelete: {
      return <ReplacementsDeleteModal {...rest} />;
    }

    case ModalType.UserLanguageAdd: {
      return <UserLanguageAddModal {...rest} />;
    }

    case ModalType.UserLanguageDelete: {
      return <UserLanguageDeleteModal {...rest} />;
    }

    case ModalType.UserLanguageEdit: {
      return <UserLanguageEditModal {...rest} />;
    }

    case ModalType.UserExpertiseAdd: {
      return <UserExpertiseAddModal {...rest} />;
    }

    case ModalType.UserExpertiseDelete: {
      return <UserExpertiseDeleteModal {...rest} />;
    }

    case ModalType.UserExpertiseEdit: {
      return <UserExpertiseEditModal {...rest} />;
    }

    case ModalType.UserQuestionAdd: {
      return <UserQuestionAddModal {...rest} />;
    }

    case ModalType.UserQuestionDelete: {
      return <UserQuestionDeleteModal {...rest} />;
    }

    case ModalType.UserQuestionEdit: {
      return <UserQuestionEditModal {...rest} />;
    }

    case ModalType.ClientsDelete: {
      return <ClientsDeleteModal {...rest} />;
    }

    case ModalType.TargetAudienceAdd: {
      return <TargetAudienceAddModal {...rest} />;
    }

    case ModalType.TargetAudienceDelete: {
      return <TargetAudienceDeleteModal {...rest} />;
    }

    case ModalType.TargetAudienceEdit: {
      return <TargetAudienceEditModal {...rest} />;
    }

    case ModalType.ProjectsAdd: {
      return <ProjectsAdd {...rest} />;
    }

    case ModalType.ProjectsDelete: {
      return <ProjectsDelete {...rest} />;
    }

    case ModalType.ContactPersonDelete: {
      return <ContactPersonDeleteModal {...rest} />;
    }

    case ModalType.ProjectsEdit: {
      return <ProjectsEdit {...rest} />;
    }

    case ModalType.PmCheckList: {
      return <PmCheckListModal {...rest} />;
    }
    case ModalType.StatusHistoryModal: {
      return <StatusHistoryModal {...rest} />;
    }
    case ModalType.AssignAssistantPMModal: {
      return <AssignAssistantPMModal {...rest} />;
    }
    /*case ModalType.WriterModal: {
      return <WriterModal {...rest} />;
    }*/
    case ModalType.EditorModal: {
      return <EditorModal {...rest} />;
    }
    case ModalType.OrderDeliveryEditModal: {
      return <OrderDeliveryEditModal {...rest} />;
    }
    case ModalType.DuplicateOrderLineModal: {
      return <DuplicateOrderLineModal {...rest} />;
    }
    case ModalType.ReportParametersModal: {
      return <ReportParametersModal {...rest} />;
    }
    case ModalType.ReportParametersDeleteModal: {
      return <ReportParametersDeleteModal {...rest} />;
    }
    case ModalType.ReportParametersEditModal: {
      return <ReportParametersEditModal {...rest} />;
    }
    case ModalType.ReportCategoryAddModal: {
      return <ReportCategoryAddModal {...rest} />;
    }
    case ModalType.ReportCategoryDeleteModal: {
      return <ReportCategoryDeleteModal {...rest} />;
    }
    case ModalType.LanguageRatesAddModal: {
      return <LanguageRatesAddModal {...rest} />;
    }
    case ModalType.LanguageRatesEditModal: {
      return <LanguageRatesEditModal {...rest} />;
    }
    case ModalType.LanguageRatesDeleteModal: {
      return <LanguageRatesDeleteModal {...rest} />;
    }
    case ModalType.ProjectsRatesAddModal: {
      return <ProjectsRatesAddModal {...rest} />;
    }
    case ModalType.ProjectsRatesEditModal: {
      return <ProjectsRatesEditModal {...rest} />;
    }
    case ModalType.ProjectsRatesDeleteModal: {
      return <ProjectsRatesDeleteModal {...rest} />;
    }
    case ModalType.ClientsRatesAddModal: {
      return <ClientsRatesAddModal {...rest} />;
    }
    case ModalType.ClientsRatesEditModal: {
      return <ClientsRatesEditModal {...rest} />;
    }
    case ModalType.ClientsRatesDeleteModal: {
      return <ClientsRatesDeleteModal {...rest} />;
    }
    case ModalType.APLanguageRateAddModal: {
      return <APLanguageRateAddModal {...rest} />;
    }
    case ModalType.APLanguageRateEditModal: {
      return <APLanguageRateEditModal {...rest} />;
    }
    case ModalType.APLanguageRateDeleteModal: {
      return <APLanguageRateDeleteModal {...rest} />;
    }
    case ModalType.ChangeUserNameModal: {
      return <ChangeUserNameModal {...rest} />;
    }
    case ModalType.APEmployeeLanguageRateAddModal: {
      return <APEmployeeLanguageRateAddModal {...rest} />;
    }
    case ModalType.APEmployeeLanguageRateEditModal: {
      return <APEmployeeLanguageRateEditModal {...rest} />;
    }
    case ModalType.APEmployeeLanguageRateDeleteModal: {
      return <APEmployeeLanguageRateDeleteModal {...rest} />;
    }
    case ModalType.APEmployeeProjectRateAddModal: {
      return <APEmployeeProjectRateAddModal {...rest} />;
    }
    case ModalType.APEmployeeProjectRateEditModal: {
      return <APEmployeeProjectRateEditModal {...rest} />;
    }
    case ModalType.APEmployeeProjectRateDeleteModal: {
      return <APEmployeeProjectRateDeleteModal {...rest} />;
    }
  }

  return null;
};

export default memo(ModalContent);

import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { GenericIdNameType } from 'types/genericTypes';
import apiCall from './apiCall';

const getClientStatusesApi = () =>
  apiCall({
    url: Endpoint.ClientStatuses,
    method: Method.Get,
  });

const getClientStatusByIdApi = (id: string) =>
  apiCall({
    url: Endpoint.ClientStatuses,
    method: Method.Get,
    urlSuffix: id,
  });

const addClientStatusesApi = (data: { name: string }) =>
  apiCall({
    url: Endpoint.ClientStatuses,
    method: Method.Post,
    data,
  });

const deleteClientStatusesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ClientStatuses,
    method: Method.Delete,
    urlSuffix,
  });

const editClientStatusesApi = ({ id, ...data }: GenericIdNameType) => {
  return apiCall({
    url: Endpoint.ClientStatuses,
    urlSuffix: id.toString(),
    method: Method.Put,
    data,
  });
};

export {
  getClientStatusesApi,
  getClientStatusByIdApi,
  addClientStatusesApi,
  editClientStatusesApi,
  deleteClientStatusesApi,
};

import { FC, memo, useEffect, useState } from 'react';
import { actions as employeeStatusActions } from 'store/slices/employeeStatusesSlice';
import { actions as employeeLanguageActions } from 'store/slices/languagesSlice';
import { actions as employeeTimezoneActions } from 'store/slices/timeZonesSlice';
import { actions as employeeCountriesActions } from 'store/slices/countriesSlice';
import { actions as employeeCurrenciesActions } from 'store/slices/currenciesSlice';
import { actions as employeePaymentActions } from 'store/slices/paymentSlice';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel, Grid, Switch, Typography } from '@mui/material';
import { TextField } from 'components';
import { FloatingForm as Form } from 'components/Forms/FloatingForm';
import l from 'helpers/l';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';
import InputRowWrapper from 'components/InputRowWrapper/InputRowWrapper';
import { Endpoint } from 'enums/APIEndpointEnum';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/lab';
import { actions as employeeActions } from '../../store/slices/employeeListSlice';

type EmployeeFormProps = {
  initialValues?: any;
  validationSchema?: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const defaultValues: any = {
  firstName: '',
  lastName: '',
  email: '',
  statusId: 0,
  languageUuid: '',
  timezoneUuid: '',
  strikes: 0,
  countryOfOriginUuid: '',
  countryOfResidenceUuid: '',
  availableHours: 0,
  currencyUuid: '',
  hourlyRate: 0,
  monthlyRate: 0,
  linkedinUrl: '',
  cvUrl: '',
  paymentMethodId: 0,
  passportNumber: '',
  isExpert: false,
  isFirstInterview: false,
  isNewEmployee: false,
  isSeniorEmployee: false,
  isSignedContract: false,
  isTranslationOnly: false,
  qa: '',
  test: '',
  paymentDetails: '',
  referredByUuid: '',
  teamLeadUuid: '',
  contactPersonUuid: '',
};

const defaultValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email adress').required('Required'),
  statusId: Yup.string().required('Required'),
  languageUuid: Yup.string().nullable(),
  timezoneUuid: Yup.string().required('Required'),
  strikes: Yup.number(),
  countryOfOriginUuid: Yup.string().nullable(),
  countryOfResidenceUuid: Yup.string().nullable(),
  availableHours: Yup.number(),
  currencyUuid: Yup.string().required('Required'),
  hourlyRate: Yup.number(),
  monthlyRate: Yup.number(),
  linkedinUrl: Yup.string(),
  cvUrl: Yup.string(),
  paymentMethodId: Yup.number(),
  passportNumber: Yup.string(),
  isExpert: Yup.bool(),
  isFirstInterview: Yup.bool(),
  isNewEmployee: Yup.bool(),
  isSeniorEmployee: Yup.bool(),
  isSignedContract: Yup.bool(),
  isisTranslationOnly: Yup.bool(),
  test: Yup.string(),
  paymentDetails: Yup.string(),
  referredByUuid: Yup.string().nullable(),
  teamLeadUuid: Yup.string().nullable(),
  contactPersonUuid: Yup.string().nullable(),
});

const EmployeeForm: FC<EmployeeFormProps> = ({
  initialValues = defaultValues,
  validationSchema = defaultValidation,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState<
    Date | null | string | undefined
  >();

  useEffect(() => {
    if (!startDate) {
      const now = Date.now();
      setStartDate(new Date(now));
    }
  }, [startDate]);

  useEffect(() => {
    dispatch(employeeStatusActions.getEmployeeStatuses());
    dispatch(employeeLanguageActions.getLanguages());
    dispatch(employeeTimezoneActions.getTimeZones());
    dispatch(employeeCountriesActions.getCountryList());
    dispatch(employeeCurrenciesActions.getCurrencyList());
    dispatch(employeePaymentActions.getPayment());
    dispatch(employeeActions.getTeamLeadsList());
  }, [dispatch]);

  const empStatusDropdown = useSelector(
    (state: RootState) => state.employeeStatuses.employeeStatuses,
  ).map((status) => ({ label: status.name, value: status.id }));
  const empLanguagesDropdown = useSelector(
    (state: RootState) => state.languages.items,
  ).map((lang) => ({ label: lang.name, value: lang.uuid }));
  const empTimezonesDropdown = useSelector(
    (state: RootState) => state.timeZones.items,
  ).map((tz) => ({ label: tz.name, value: tz.uuid }));
  const empCountriesDropdown = useSelector(
    (state: RootState) => state.countries.items,
  ).map((items) => ({ label: items.name, value: items.uuid }));

  const empCurrenciesDropdown = useSelector(
    (state: RootState) => state.currencies.items,
  ).map((currency) => ({
    label: currency.code + ' - ' + currency.name,
    value: currency.uuid,
  }));
  const empPaymentMethodsDropdown = useSelector(
    (state: RootState) => state.payment.payment,
  ).map((payment) => ({ label: payment.name, value: payment.id }));

  const empReffereeDropdown = useSelector(
    (state: RootState) => state.employees.employees,
  ).map((emp: any) => ({
    label: `${emp.firstName} ${emp.lastName}`,
    value: emp.uuid,
  }));

  const teamLeadDropdown = useSelector(
    (state: RootState) => state.employees.teamLeads,
  ).map((emp: any) => ({
    label: `${emp.firstName} ${emp.lastName}`,
    value: emp.uuid,
  }));

  const contactPersonsDropdown = useSelector(
    (state: RootState) => state?.contactPersons?.items,
  ).map((conPerson: any) => ({
    label: conPerson?.nick,
    value: conPerson?.uuid,
  }));

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit(values);
    },
  });

  const currencyLabel = formik?.values?.currency
    ? formik?.values?.currency?.name + ' ' + formik?.values?.currency?.code
    : '';

  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnSubmitLabel="Save"
      btnCancelLabel="Cancel"
      sx={{ display: 'flex', flexFlow: 'wrap' }}>
      <Grid container spacing={2}>
        <Grid item>
          <InputRowWrapper
            label={l('COMMON.FIRSTNAME')}
            labelWidth="100px"
            inputWidth="45%">
            <TextField
              name="firstName"
              size="small"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.firstName)}
              helperText={formik.errors.firstName}
              pressEnter={formik.submitForm}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('COMMON.LASTNAME')}
            labelWidth="100px"
            inputWidth="45%">
            <TextField
              name="lastName"
              size="small"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.lastName)}
              helperText={formik.errors.lastName}
              pressEnter={formik.submitForm}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('COMMON.EMAIL')}
            labelWidth="70px"
            inputWidth="40%">
            <TextField
              name="email"
              fullWidth
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.email)}
              helperText={formik.errors.email}
              pressEnter={formik.submitForm}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('EMPLOYEE.STATUS')}
            labelWidth="150px"
            inputWidth="25%">
            <TextField
              select
              fullWidth
              name="statusId"
              size="small"
              value={formik.values.statusId}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.statusId)}
              helperText={formik.errors.statusId}
              pressEnter={formik.submitForm}>
              {createMenuItems(empStatusDropdown)}
            </TextField>
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('TIMEZONE')}
            labelWidth="100px"
            inputWidth="25%">
            <SuggestedDropdown
              url={Endpoint.TimeZones}
              inputId="timezoneUuid"
              initialData={empTimezonesDropdown}
              inputValue={{
                label: formik?.values?.timezone?.name || '',
                value: formik?.values?.timezone?.uuid || '',
              }}
              error={Boolean(formik.errors.timezoneUuid)}
              helperText={formik.errors.timezoneUuid}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              orderBy="name"
              order="ASC"
              style={{ display: 'flex', flexDirection: 'row' }}
              formikHook={formik}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('MAIN_LANGUAGE')}
            labelWidth="150px"
            inputWidth="20%">
            <SuggestedDropdown
              url={Endpoint.Languages}
              inputId="languageUuid"
              initialData={empLanguagesDropdown}
              inputValue={{
                label: formik?.values?.language?.name || '',
                value: formik?.values?.language?.uuid || '',
              }}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              orderBy="code"
              order="ASC"
              style={{ display: 'flex', flexDirection: 'row' }}
              formikHook={formik}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('EXPERT')}
            labelWidth="60px"
            inputWidth="100px">
            <Switch
              id="isExpert"
              checked={formik.values.isExpert}
              size="small"
              onChange={formik.handleChange}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('STRIKES')}
            labelWidth="70px"
            inputWidth="20%">
            <TextField
              name="strikes"
              size="small"
              value={formik.values.strikes}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.strikes)}
              helperText={formik.errors.strikes}
              pressEnter={formik.submitForm}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('COUNTRY_OF_ORIGIN')}
            labelWidth="160px"
            inputWidth="35%">
            <SuggestedDropdown
              url={Endpoint.Countries}
              inputId="countryOfOriginUuid"
              initialData={empCountriesDropdown}
              inputValue={{
                label: formik?.values?.countryOfOrigin?.name || '',
                value: formik?.values?.countryOfOrigin?.uuid || '',
              }}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              orderBy="name"
              order="ASC"
              style={{ display: 'flex', flexDirection: 'row' }}
              formikHook={formik}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('COUNTRY_OF_RESIDENCE')}
            labelWidth="200px"
            inputWidth="35%">
            <SuggestedDropdown
              url={Endpoint.Countries}
              inputId="countryOfResidenceUuid"
              initialData={empCountriesDropdown}
              inputValue={{
                label: formik?.values?.countryOfResidence?.name || '',
                value: formik?.values?.countryOfResidence?.uuid || '',
              }}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              orderBy="name"
              order="ASC"
              style={{ display: 'flex', flexDirection: 'row' }}
              formikHook={formik}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('AVAILABLE_HOURS')}
            labelWidth="140px"
            inputWidth="15%">
            <TextField
              name="availableHours"
              size="small"
              value={formik.values.availableHours}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.availableHours)}
              helperText={formik.errors.availableHours}
              pressEnter={formik.submitForm}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('CURRENCY')}
            labelWidth="100px"
            inputWidth="26%">
            <SuggestedDropdown
              url={Endpoint.Currencies}
              inputId="currencyUuid"
              error={Boolean(formik.errors.currencyUuid)}
              helperText={formik.errors.currencyUuid}
              initialData={empCurrenciesDropdown}
              inputValue={{
                label: currencyLabel,
                value: formik?.values?.currency?.uuid || '',
              }}
              dropdownLabel={['name', 'code']}
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              orderBy="name"
              order="ASC"
              style={{ display: 'flex', flexDirection: 'row' }}
              formikHook={formik}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('HOURLY_RATES')}
            labelWidth="110px"
            inputWidth="10%">
            <TextField
              name="hourlyRate"
              size="small"
              value={formik.values.hourlyRate}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.hourlyRate)}
              helperText={formik.errors.hourlyRate}
              pressEnter={formik.submitForm}
            />
            <Typography sx={{ marginLeft: '1ch' }}>
              {formik?.values?.currency?.code}
            </Typography>
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('EMPLOYEE.MONTHLY_RATE')}
            labelWidth="110px"
            inputWidth="10%">
            <TextField
              name="monthlyRate"
              size="small"
              value={formik.values.monthlyRate}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.hourlyRate)}
              helperText={formik.errors.hourlyRate}
              pressEnter={formik.submitForm}
            />
            <Typography sx={{ marginLeft: '1ch' }}>
              {formik?.values?.currency?.code}
            </Typography>
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('EMPLOYEE.START_DATE')}
            labelWidth="100px"
            inputWidth="10%">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                value={startDate}
                onChange={(newValue: any) => {
                  setStartDate(newValue);
                }}
                minDate={new Date('2018-01-01T00:00')}
                inputFormat="yyyy/MM/dd hh:mm a"
                mask="___/__/__ __:__ _M"
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('LINKEDIN_URL')}
            labelWidth="110px"
            inputWidth="45%">
            <TextField
              name="linkedinUrl"
              size="small"
              value={formik.values.linkedinUrl}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.linkedinUrl)}
              helperText={formik.errors.linkedinUrl}
              pressEnter={formik.submitForm}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('CV_URL')}
            labelWidth="70px"
            inputWidth="40%">
            <TextField
              name="cvUrl"
              size="small"
              value={formik.values.cvUrl}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.cvUrl)}
              helperText={formik.errors.cvUrl}
              pressEnter={formik.submitForm}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('FIRST_INTERVIEW')}
            labelWidth="130px"
            inputWidth="12%">
            <FormLabel>{}</FormLabel>
            <Switch
              id="isFirstInterview"
              checked={formik.values.isFirstInterview}
              size="small"
              onChange={formik.handleChange}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('NEW_EMPLOYEE')}
            labelWidth="140px"
            inputWidth="12%">
            <Switch
              id="isNewEmployee"
              checked={formik.values.isNewEmployee}
              size="small"
              onChange={formik.handleChange}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={'Translation Only'}
            labelWidth="140px"
            inputWidth="12%">
            <Switch
              id="isTranslationOnly"
              checked={formik.values.isTranslationOnly}
              size="small"
              onChange={formik.handleChange}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('SENIOR_EMPLOYEE')}
            labelWidth="150px"
            inputWidth="12%">
            <Switch
              id="isSeniorEmployee"
              checked={formik.values.isSeniorEmployee}
              size="small"
              onChange={formik.handleChange}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('PAYMENT_METHOD')}
            labelWidth="150px"
            inputWidth="25%">
            <TextField
              select
              fullWidth
              name="paymentMethodId"
              size="small"
              value={formik.values.paymentMethodId}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.paymentMethodId)}
              helperText={formik.errors.paymentMethodId}
              pressEnter={formik.submitForm}>
              {createMenuItems(empPaymentMethodsDropdown)}
            </TextField>
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('PASSPORT_NUMBER')}
            labelWidth="150px"
            inputWidth="33%">
            <TextField
              name="passportNumber"
              size="small"
              value={formik.values.passportNumber}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.passportNumber)}
              helperText={formik.errors.passportNumber}
              pressEnter={formik.submitForm}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('CONTRACT_SIGNED')}
            labelWidth="170px"
            inputWidth="25%">
            <Switch
              id="isSignedContract"
              checked={formik.values.isSignedContract}
              size="small"
              onChange={formik.handleChange}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('QUESTIONS_AND_ANSWERS')}
            labelWidth="200px"
            inputWidth="49%">
            <FormLabel>{}</FormLabel>
            <TextField
              name="qa"
              size="small"
              multiline
              rows={6}
              sx={{ height: 'max-content', marginBottom: '35px' }}
              value={formik.values.qa || ''}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.qa)}
              helperText={formik.errors.qa}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('PAYMENT_DETAILS')}
            labelWidth="16ch"
            inputWidth="45%">
            <TextField
              name="paymentDetails"
              size="small"
              multiline
              rows={6}
              sx={{ height: 'max-content', marginBottom: '35px' }}
              value={formik.values.paymentDetails || ''}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.paymentDetails)}
              helperText={formik.errors.paymentDetails}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('REFERRED_BY_EMPLOYEE')}
            labelWidth="200px"
            inputWidth="30%">
            <SuggestedDropdown
              url={Endpoint.EmployeesList}
              inputId="referredByUuid"
              initialData={empReffereeDropdown}
              inputValue={{
                label: formik?.values?.referredBy?.firstName
                  ? formik?.values?.referredBy?.firstName +
                    ' ' +
                    formik?.values?.referredBy?.lastName
                  : '',
                value: formik?.values?.referredBy?.uuid || '',
              }}
              dropdownLabel={['firstName', 'lastName']}
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              orderBy="name"
              order="ASC"
              style={{ display: 'flex', flexDirection: 'row' }}
              formikHook={formik}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={'Team Leader'}
            labelWidth="200px"
            inputWidth="30%">
            <SuggestedDropdown
              url={Endpoint.TeamLeads}
              inputId="teamLeadUuid"
              initialData={teamLeadDropdown}
              inputValue={{
                label: formik?.values?.teamLead?.firstName
                  ? formik?.values?.teamLead?.firstName +
                    ' ' +
                    formik?.values?.teamLead?.lastName
                  : '',
                value: formik?.values?.teamLead?.uuid || '',
              }}
              dropdownLabel={['firstName', 'lastName']}
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              orderBy="name"
              order="ASC"
              style={{ display: 'flex', flexDirection: 'row' }}
              formikHook={formik}
            />
          </InputRowWrapper>
        </Grid>
        <Grid item>
          <InputRowWrapper
            label={l('CONTACT_PERSON')}
            labelWidth="200px"
            inputWidth="30%">
            <SuggestedDropdown
              url={Endpoint.ContactPersons}
              inputId="contactPersonUuid"
              initialData={contactPersonsDropdown}
              dropdownLabel="nick"
              dropdownValue="uuid"
              inputValue={{
                label: formik?.values?.contactPerson?.nick || '',
                value: formik?.values?.contactPerson?.uuid || '',
              }}
              dataLocation="resultDataItems"
              filter
              formikHook={formik}
              style={{ display: 'flex', flexDirection: 'row' }}
            />
          </InputRowWrapper>
        </Grid>
      </Grid>
    </Form>
  );
};

export default memo(EmployeeForm);

import { FC, memo, useCallback } from 'react';
import { actions as clientStatusesActions } from 'store/slices/clientStatusesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import { RootState } from 'store';
import ClientStatusesForm from 'components/Forms/ClientStatusesForm';

const ClientStatusEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data
      ? state.clientStatuses.clientStatuses.find(
          (entry) => entry?.name === data,
        )
      : null,
  );

  const submitHandler = useCallback(
    (values: { name: string }) =>
      dispatch(clientStatusesActions.editClientStatuses({ ...values })),
    [dispatch, entry?.name],
  );

  return entry ? (
    <ClientStatusesForm
      title={'Edit Client Status'}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(ClientStatusEditModal);

import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/clientStatusesSlice';
import { actions as commonActions } from 'store/slices/common';

import { PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';
import {
  getClientStatusesApi,
  addClientStatusesApi,
  deleteClientStatusesApi,
  editClientStatusesApi,
} from 'store/api/clientStatusesAPI';

function* getClientStatuses() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<GenericIdNameType[]> = yield call(
    getClientStatusesApi,
  );
  if (result?.data?.length) {
    yield put(actions.setClientStatuses(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addClientStatuses(
  action: PayloadAction<{
    name: string;
  }>,
) {
  const data = action.payload;
  const {
    result,
    error,
  }: APIResult<{
    name: string;
  }> = yield call(addClientStatusesApi, data);
  if (result?.status === 201) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getClientStatusesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setClientStatuses(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteClientStatuses(action: PayloadAction<GenericIdNameType>) {
  const { id, name } = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteClientStatusesApi,
    id! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getClientStatusesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setClientStatuses(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Client status is in use, can not be deleted!',
        severity: 'error',
      }),
    );
  }
}

function* editClientStatuses(action: PayloadAction<GenericIdNameType>) {
  const { name, id } = action.payload;

  const { result, error }: APIResult<GenericIdNameType> = yield call(
    editClientStatusesApi,
    { id, name },
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getClientStatusesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setClientStatuses(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Client status name is taken',
        severity: 'error',
      }),
    );
  }
}

export default function* clientStatusesWatcher() {
  yield takeLatest(actions.getClientStatuses.type, getClientStatuses);
  yield takeLatest(actions.addClientStatuses.type, addClientStatuses);
  yield takeLatest(actions.deleteClientStatuses.type, deleteClientStatuses);
  yield takeLatest(actions.editClientStatuses.type, editClientStatuses);
}

import { FC, memo, useCallback, useEffect } from 'react';
import { actions as orderLineStatusesActions } from 'store/slices/orderLineStatusesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import { RootState } from 'store';
import OrderLineStatusesForm from 'components/Forms/OrderLineStatusesForm';
import {
  OrderLineStatusActionEdit,
  OrderLineStatusActionEntry,
} from 'types/orderLineStatusesTypes';
import { actions as clientStatusesActions } from '../../../store/slices/clientStatusesSlice';

const OrderLineStatusesEditModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    state.orderLineStatuses.orderLineStatuses.find(
      (entry: any) => entry?.uuid === props.data,
    ),
  );

  const submitHandler = useCallback(
    (values: OrderLineStatusActionEntry) => {
      const uuid: string = props.data;
      const data = values;
      const req: OrderLineStatusActionEdit = { uuid, data };
      dispatch(orderLineStatusesActions.editOrderLineStatuses(req));
    },
    [dispatch],
  );

  const clientStatusesDropdown = useSelector((state: RootState) => {
    return state?.clientStatuses?.clientStatuses;
  }).map((entry) => ({
    value: entry.id,
    label: entry.name,
  }));

  useEffect(() => {
    dispatch(clientStatusesActions.getClientStatuses());
  }, [dispatch]);

  return entry ? (
    <OrderLineStatusesForm
      title={l('ORDER_LINE_STATUSES.MODAL_EDIT_TITLE')}
      onSubmit={submitHandler}
      initialValues={{
        ...entry,
        /*@ts-ignore*/
        clientStatus: clientStatusesDropdown.find(
          (cs) => cs.label === entry?.clientStatus,
        )?.value,
      }}
      {...props}
    />
  ) : (
    <></>
  );
};

export default memo(OrderLineStatusesEditModal);

import { FC, memo, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel } from '@mui/material';
import { TextField } from 'components';
import l from 'helpers/l';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { useDispatch, useSelector } from 'react-redux';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { RootState } from 'store';
import { actions as languageActions } from 'store/slices/languagesSlice';
import { actions as projectsActions } from 'store/slices/projectsSlice';
import { Endpoint } from 'enums/APIEndpointEnum';
import { createMenuItems } from 'helpers/dropdown';
import { sanitizeNumericInput } from 'helpers/sanitzeNumericInput';
import { actions as contactActions } from 'store/slices/contactPersonsSlice';

const validationSchema = Yup.object().shape({
  languageUuid: Yup.string()
    .required('This field is required!')
    .notOneOf(
      [Yup.ref('sourceLanguageUuid')],
      'Language must not have the same value as Source Language',
    ),
  contactPersonUuid: Yup.string().required('This field is required!'),
  ratePerWord: Yup.number().required('This field is required!'),
  ratePerArticle: Yup.number().nullable(),
});

const defaultValues: any = {
  sourceLanguageUuid: '',
  languageUuid: '',
  contactPersonUuid: '',
  ratePerWord: '',
  ratePerArticle: '',
};

type IARClientRateForm = {
  initialValues?: any;
  title: string;
  onSubmit: (values: any) => void;
} & GenericModalProps;

const ARClientRateForm: FC<IARClientRateForm> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const languagesDropdown = useSelector(
    (state: RootState) => state?.languages?.items,
  ).map((entry) => ({ label: entry.name, value: entry.uuid }));

  const contactPersonsDropdown = useSelector(
    (state: RootState) => state?.contactPersons?.items,
  ).map((conPerson: any) => ({
    label: conPerson?.nick,
    value: conPerson?.uuid,
  }));

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      if (values?.sourceLanguageUuid?.length < 1) {
        delete values.sourceLanguageUuid;
      }
      onSubmit(values);
    },
  });

  const contactPersons = useSelector(
    (state: RootState) => state.contactPersons.items,
  ).map(({ nick, uuid }) => ({
    label: nick,
    value: uuid,
  }));

  useEffect(() => {
    dispatch(languageActions.getLanguages());
    dispatch(projectsActions.getProjects());

    dispatch(contactActions.getContactPersonsList());
  }, [dispatch]);

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}
      style={{ maxHeight: '95%', overflowY: 'auto' }}>
      <FormLabel>{l('SOURCE_LANGUAGE')}</FormLabel>
      <SuggestedDropdown
        url={Endpoint.Languages}
        inputId="sourceLanguageUuid"
        initialData={languagesDropdown}
        inputValue={
          initialValues?.sourceLanguageUuid
            ? {
                label: initialValues.sourceLanguage,
                value: initialValues.sourceLanguageUuid,
              }
            : ''
        }
        dropdownLabel="name"
        dropdownValue="uuid"
        dataLocation="resultDataItems"
        error={Boolean(formik.errors.sourceLanguageUuid)}
        helperText={formik.errors.sourceLanguageUuid}
        formikHook={formik}
        style={{ display: 'flex', flexDirection: 'row' }}
      />
      <FormLabel>{l('LANGUAGE')}</FormLabel>
      <SuggestedDropdown
        url={Endpoint.Languages}
        inputId="languageUuid"
        error={Boolean(formik.errors.languageUuid)}
        helperText={formik.errors.languageUuid}
        initialData={languagesDropdown}
        inputValue={
          initialValues?.language
            ? {
                label: initialValues?.language,
                value: initialValues?.languageUuid,
              }
            : ''
        }
        dropdownLabel="name"
        dropdownValue="uuid"
        dataLocation="resultDataItems"
        formikHook={formik}
        style={{ display: 'flex', flexDirection: 'row' }}
      />
      <FormLabel>{l('CONTACT_PERSON')}</FormLabel>
      <SuggestedDropdown
        url={Endpoint.ContactPersons}
        inputId="contactPersonUuid"
        initialData={contactPersons}
        inputValue={
          formik?.values?.contactPerson
            ? {
                label: formik?.values?.contactPerson?.nick,
                value: formik?.values?.contactPerson?.uuid,
              }
            : ''
        }
        dropdownLabel="nick"
        dropdownValue="uuid"
        dataLocation="resultDataItems"
        error={Boolean(formik.errors.contactPersonUuid)}
        helperText={formik.errors.contactPersonUuid}
        formikHook={formik}
      />
      <FormLabel>{l('RATE_PER_WORD')}</FormLabel>
      <TextField
        name="ratePerWord"
        size="small"
        value={formik?.values?.ratePerWord || ''}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.ratePerWord)}
        helperText={formik.errors.ratePerWord}
        pressEnter={formik.submitForm}
        onInput={(e: any) => {
          e.target.value = sanitizeNumericInput(e.target.value);
        }}
      />
      <FormLabel>{l('RATE_PER_ARTICLE')}</FormLabel>
      <TextField
        name="ratePerArticle"
        size="small"
        value={formik?.values?.ratePerArticle || ''}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.ratePerArticle)}
        helperText={formik.errors.ratePerArticle}
        pressEnter={formik.submitForm}
        onInput={(e: any) => {
          e.target.value = sanitizeNumericInput(e.target.value);
        }}
      />
    </SimpleFormModal>
  );
};

export default memo(ARClientRateForm);

import { FC, memo, useCallback } from 'react';
import { actions as clientStatusesActions } from 'store/slices/clientStatusesSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import ClientStatusesForm from 'components/Forms/ClientStatusesForm';

const ClientStatusAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: { name: string }) => {
      console.log('values', values);
      dispatch(clientStatusesActions.addClientStatuses(values));
    },
    [dispatch],
  );

  return (
    <ClientStatusesForm
      title={'Add Client Status'}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(ClientStatusAddModal);

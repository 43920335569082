import { memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { actions as orderListActions } from 'store/slices/orderListSlice';
import l from 'helpers/l';
import OrderListForm from 'components/Forms/OrderListForm';

function OrderListEditPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const orderList = useSelector((state: RootState) => state.orderList?.items);
  const [exactOrder] = orderList.filter((order: any) => order?.uuid === id);

  const initialValues = {
    client: exactOrder?.contactPerson?.client?.id,
    contactPersonUuid: exactOrder?.contactPerson?.uuid,
    contactPersonName: exactOrder?.contactPerson?.nick,
    accountManager: exactOrder?.contactPerson?.accountManager?.uuid,
    orderDate: exactOrder?.orderedAt,
    dueDate: exactOrder?.dueDatetime,
    isCorrection: exactOrder?.isCorrection,
    isRatePerArticleClient: exactOrder?.isRatePerArticleClient,
    comments: exactOrder?.comments,
  };

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      uuid: id,
      navigate,
    };
    dispatch(orderListActions.editEntries({ ...data, navigate }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageLayout title={'Client Edit Order'} fullWidth={true}>
      <OrderListForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialValues={initialValues}
      />
    </PageLayout>
  );
}

export default memo(OrderListEditPage);

import { FC, memo, useEffect } from 'react';
import {
  OrderLineStatusActionEntry,
  OrderLineStatusType,
} from 'types/orderLineStatusesTypes';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel, Switch } from '@mui/material';
import { TextField } from 'components';
import l from 'helpers/l';
import InputWrapper from 'components/containers/InputWrapper/InputWrapper';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { Color, ColorPicker } from 'material-ui-color';
import { GenericModalProps } from 'types/genericTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { actions as clientStatusesActions } from 'store/slices/clientStatusesSlice';
import { createMenuItems } from 'helpers/dropdown';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  needsDescription: Yup.string().required('Required'),
  isActive: Yup.boolean().required('Required'),
  isSendMail: Yup.boolean().required('Required'),
  isSendNotification: Yup.boolean().required('Required'),
  color: Yup.string().required('Required'),
  clientStatus: Yup.string().required('Required'),
});

type OrderLineStatusesFormProps = {
  initialValues: OrderLineStatusType | OrderLineStatusActionEntry;
  title: string;
  onSubmit: (values: OrderLineStatusActionEntry) => void;
} & GenericModalProps;

const OrderLineStatusesForm: FC<OrderLineStatusesFormProps> = ({
  onClose,
  initialValues,
  title,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: OrderLineStatusActionEntry) => {
      onSubmit({
        ...values,
      });
    },
  });

  const clientStatusesDropdown = useSelector((state: RootState) => {
    return state?.clientStatuses?.clientStatuses;
  }).map((entry) => ({
    value: entry.id,
    label: entry.name,
  }));

  useEffect(() => {
    dispatch(clientStatusesActions.getClientStatuses());
  }, [dispatch]);

  return (
    <SimpleFormModal
      onSubmit={formik.submitForm}
      onClose={onClose}
      title={title}>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.NAME')}</FormLabel>
        <TextField
          name="name"
          size="small"
          value={formik.values.name}
          onChange={formik.handleChange}
          autoComplete="on"
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
          pressEnter={formik.submitForm}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{'Client Status'}</FormLabel>
        <TextField
          select
          name="clientStatus"
          size="small"
          value={formik?.values?.clientStatus}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.clientStatus)}
          helperText={formik.errors.clientStatus}>
          {createMenuItems(clientStatusesDropdown)}
        </TextField>
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.IS_ACTIVE')}</FormLabel>
        <Switch
          id="isActive"
          checked={formik.values.isActive}
          size="small"
          onChange={formik.handleChange}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.NEEDS_DESCRIPTION')}</FormLabel>
        <Switch
          id="needsDescription"
          checked={formik.values.needsDescription}
          size="small"
          onChange={formik.handleChange}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.IS_SEND_MAIL')}</FormLabel>
        <Switch
          id="isSendMail"
          checked={formik.values.isSendMail}
          size="small"
          onChange={formik.handleChange}
        />
      </InputWrapper>
      <InputWrapper>
        <FormLabel>{l('ORDER_LINE_STATUSES.IS_SEND_NOTIFICATION')}</FormLabel>
        <Switch
          id="isSendNotification"
          checked={formik.values.isSendNotification}
          size="small"
          onChange={formik.handleChange}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.COLOR')}</FormLabel>
        <ColorPicker
          defaultValue={'#000000'}
          value={formik.values.color}
          disableTextfield={false}
          hideTextfield={false}
          deferred={true}
          disableAlpha={true}
          disablePlainColor={false}
          onChange={(c: Color) => {
            const format = `#${c.hex}`;
            formik.setFieldValue('color', format);
          }}
          hslGradient={false}
        />
      </InputWrapper>
    </SimpleFormModal>
  );
};

export default memo(OrderLineStatusesForm);

import { all, fork } from 'redux-saga/effects';
import languagesWatcher from './languagesSaga';
import timeZonesWatcher from './timeZonesSaga';
import authWatcher from './authSaga';
import UserWatcher from './user';
import CurrenciesWatcher from './currenciesSaga';
import CountriesWatcher from './countriesSaga';
import employeesListWatcher from './employeesListSaga';
import EmployeeSatuses from './employeeStatusesSaga';
import Questions from './questionsSaga';
import ExpertiseWatcher from './expertiseSaga';
import EmployeeSatusesWatcher from './employeeStatusesSaga';
import TonesOfVoiceWatcher from './voicesSaga';
import PaymentWatcher from './paymentSaga';
import ArticleTypesWatcher from './articleTypesSaga';
import RolesWatcher from './rolesSaga';
import OrderTypesWatcher from './orderTypesSaga';
import ClientStatusesWatcher from './clientStatusesSaga';
import OrderEntriesWatcher from './orderEntriesSaga';
import OrderListWatcher from './orderListSaga';
import OrderLineStatusesWatcher from './orderLineStatusesSaga';
import OrderLineStatusRolesWatcher from './orderLineStatusRolesSaga';
import OrderLineStatusTransitionWatcher from './orderLineStatusTransitionSaga';
import OrderLinesDeliveryWatcher from './orderLinesDeliverySaga';
import ChecklistsWatcher from './checklistsSaga';
import ChecklistsRolesWatcher from './checklistsRolesSaga';
import ChecklistsContactPersonsWatcher from './checklistsContactPersonsSaga';
import SpeakersWatcher from './speakersSaga';
import ContactPersonsWatcher from './contactPersonsSaga';
import ClientsWatcher from './clientsSaga';
import TargetAudienceWatcher from './targetAudienceSaga';
import ProjectsWatcher from './projectsSaga';
import OrderLinesWatcher from './orderLinesSaga';
import ProjectWatcher from './projectManagerSaga';
import OrderLineStatusLogWatcher from './orderLineStatusLogSaga';
import ReportParametersWatcher from './reports/reportParametersSaga';
import messagesWatcher from './messagesSaga';
import ReportCategoriesWatcher from './reports/reportCategoriesSaga';
import ReportsWatcher from './reports/reportsSaga';
import ReportManagementWatcher from './reports/reportManagement/reportManagementSaga';
import ARLanguagesRateWatcher from './arLanguagesRateSaga'; // ar
import ARProjectsRateWatcher from './arProjectsRateSaga'; // ar
import ARClientsRateWatcher from './arClientsRateSaga'; // ar
import APLanguagesRateWatcher from './apLanguageRateSaga'; // ap
import APEmployeeLanguageRateWatcher from './apEmployeeLanguageRateSaga'; // ap
import APEmployeeProjectRateWatcher from './apEmployeeProjectRateSaga'; // ap

export default function* rootSaga() {
  yield all([
    fork(languagesWatcher),
    fork(authWatcher),
    fork(UserWatcher),
    fork(timeZonesWatcher),
    fork(CurrenciesWatcher),
    fork(CountriesWatcher),
    fork(employeesListWatcher),
    fork(EmployeeSatuses),
    fork(Questions),
    fork(ExpertiseWatcher),
    fork(EmployeeSatusesWatcher),
    fork(TonesOfVoiceWatcher),
    fork(PaymentWatcher),
    fork(ArticleTypesWatcher),
    fork(OrderTypesWatcher),
    fork(OrderEntriesWatcher),
    fork(OrderListWatcher),
    fork(OrderLineStatusesWatcher),
    fork(OrderLineStatusRolesWatcher),
    fork(OrderLineStatusTransitionWatcher),
    fork(OrderLinesDeliveryWatcher),
    fork(RolesWatcher),
    fork(ChecklistsWatcher),
    fork(ChecklistsRolesWatcher),
    fork(ChecklistsContactPersonsWatcher),
    fork(SpeakersWatcher),
    fork(ContactPersonsWatcher),
    fork(ClientsWatcher),
    fork(TargetAudienceWatcher),
    fork(ProjectsWatcher),
    fork(OrderLinesWatcher),
    fork(ProjectWatcher),
    fork(OrderLineStatusLogWatcher),
    fork(ReportParametersWatcher),
    fork(ReportManagementWatcher),
    fork(ReportCategoriesWatcher),
    fork(ReportsWatcher),
    fork(messagesWatcher),
    fork(ARLanguagesRateWatcher),
    fork(ARProjectsRateWatcher),
    fork(ARClientsRateWatcher),
    fork(APLanguagesRateWatcher),
    fork(APEmployeeLanguageRateWatcher),
    fork(APEmployeeProjectRateWatcher),
    fork(ClientStatusesWatcher),
  ]);
}
